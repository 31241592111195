
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class MultiplierSlider extends Vue {
  @Prop({ default: 1 })
  min!: number;
  @Prop({ default: 10 })
  max!: number;
  @Prop({ default: 1 })
  step!: number;
  @Prop({ default: "x" })
  thumbLabelSuffix!: string;
  @Prop()
  multiplier!: number;

  hover = false;

  get value() {
    return this.multiplier;
  }
  set value(value: number) {
    this.$emit("update:multiplier", value);
  }
}
