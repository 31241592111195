import CcxtPrivate from "@/service/CcxtPrivate";
import { Account, AccountInfos, Dictionary, Market, MaxBorrowableBag, MaxBorrowablePostTransfer, Ticker } from "ccxt";
import { ExchangeMarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import { ArbiOrder } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCreator";
import { WithdrawFeePercentMap } from "@/service/arbitrage-checker/ArbitrageTypes";

export class SideData {
  constructor(
    public exchange: string,
    public ccxtExchange: CcxtPrivate,
    public market: Market,
    public balance: ExchangeMarketBalance,
    public maxBorrowableBag: MaxBorrowableBag,
    public maxBorrowablePostTransfer: MaxBorrowablePostTransfer,
    private _usedAccount: Account,
    public accountInfos: AccountInfos,
    public tickers: Dictionary<Ticker>,
    public takerFee: number, //In percentage, for example 0.001
    public usedNetwork: string | undefined,
    public withdrawFeeByNetworks: Dictionary<number> | undefined,
    public withdrawFeePercentMap: WithdrawFeePercentMap | undefined,
    public arbiOrders: ArbiOrder[]
  ) {}

  calculateWithdrawFee(amount: number) {
    if (this.usedNetwork === undefined) {
      return;
    }
    const withdrawPercent = this.withdrawFeePercentMap?.maxValue;
    const withdrawByNetwork = this.withdrawFeeByNetworks?.[this.usedNetwork];
    if (withdrawByNetwork === undefined) {
      return;
    }

    let withdrawFee = withdrawByNetwork;
    if (withdrawPercent !== undefined && amount !== undefined) {
      withdrawFee += withdrawPercent * amount;
    }
    return withdrawFee;
  }

  calculateWithdrawFeeReverse(amountAfterWithdraw: number) {
    if (this.usedNetwork === undefined) {
      return;
    }
    const withdrawPercent = this.withdrawFeePercentMap?.maxValue;
    const withdrawByNetwork = this.withdrawFeeByNetworks?.[this.usedNetwork];
    if (withdrawByNetwork === undefined) {
      return;
    }

    let withdrawFee = withdrawByNetwork;
    if (withdrawPercent !== undefined && amountAfterWithdraw !== undefined) {
      const amount = (amountAfterWithdraw + withdrawFee) / (1 - withdrawPercent);
      withdrawFee += withdrawPercent * amount;
    }
    return withdrawFee;
  }

  checkMarginModeExists(account: Account) {
    const marginMode = this.accountInfos[account]?.marginMode;
    if (marginMode === "cross" && !this.market.crossMargin) {
      //console.log(this.exchange, "Cross margin mode does not exists for market: " + this.market.symbol);
      return false;
    } else if (marginMode === "isolated" && !this.market.isolatedMargin) {
      //console.log(this.exchange, "Isolated margin mode does not exists for market: " + this.market.symbol);
      return false;
    }
    return true;
  }

  getAccountInfo(account: Account) {
    return this.accountInfos[account];
  }

  get usedAccount() {
    return this._usedAccount;
  }

  set usedAccount(account) {
    if (!this.checkMarginModeExists(account)) {
      return;
    }
    this._usedAccount = account;
  }

  getUsedMarketBalance() {
    return this.balance[this.usedAccount];
  }

  getUsedAccountInfo() {
    return this.accountInfos[this.usedAccount];
  }

  getUsedMarginMode() {
    return this.getUsedAccountInfo()?.marginMode ?? null;
  }

  getUsedMaxBorrowable() {
    const marginMode = this.getUsedMarginMode();
    if (marginMode === null) {
      return undefined;
    }
    return this.maxBorrowableBag[marginMode];
  }

  getUsedMaxBorrowablePostTransfer() {
    return this.maxBorrowablePostTransfer[this.usedAccount];
  }
}
