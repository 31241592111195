
import Vue from "vue";
import Component from "vue-class-component";
import { ArbitrageHandler } from "@/service/arbitrage-checker/ArbitrageHandler";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";
import OrderBookProgress from "@/components/ArbitrageDetails/OrderBookProgress.vue";
import OrderBookTable from "@/components/ArbitrageDetails/OrderBookTable.vue";
import ConnectionIcon from "@/components/ArbitrageDetails/ConnectionIcon.vue";
import ArbitrageStepsTable from "@/components/ArbitrageDetails/ArbitrageStepsTable.vue";
import ArbitrageStepsChart from "@/components/ArbitrageDetails/ArbitrageStepsChart.vue";
import ArbitrageStepsChart2 from "@/components/ArbitrageDetails/ArbitrageStepsChart2.vue";
import ChartCardTemplate from "@/components/ArbitrageDetails/ChartCardTemplate.vue";
import { mdiArrowULeftTop, mdiRefresh } from "@mdi/js";
import { Watch } from "vue-property-decorator";
import BalancesTable from "@/components/ArbitrageDetails/BalancesTable.vue";
import { DataLoader } from "@/service/arbitrage-checker/ArbitrageOrder/DataLoader";
import {
  ArbitrageCalculator,
  ArbitrageCalculatorResult,
} from "@/service/arbitrage-checker/ArbitrageOrder/ArbitrageCalculator";
import OrderTable from "@/components/ArbitrageDetails/OrderTable.vue";
import { OrderCalculator, OrderCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCalculator";
import { ArbiAction, OrderCreator } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCreator";
import { MarketSide, ArbiSide } from "ccxt";
import { TransferCreator } from "@/service/arbitrage-checker/ArbitrageOrder/TransferCreator";
import BalanceSummary from "@/components/ArbitrageDetails/BalanceSummary.vue";
import CheckList from "@/components/ArbitrageDetails/CheckList.vue";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import DoArbitrage from "@/components/ArbitrageDetails/DoArbitrage.vue";
import ChartZoom from "@/components/ArbitrageDetails/Components/ChartZoom.vue";
import ArbiResult from "@/components/ArbitrageDetails/Components/ArbiResult.vue";
import BoringMarkets from "@/components/Arbitrage/BoringMarkets.vue";
import BuySellArbiButtons from "@/components/ArbitrageDetails/Components/BuySellArbiButtons.vue";
import ErrorMessage from "@/components/ArbitrageDetails/Components/ErrorMessage.vue";
import ResultTable from "@/components/ArbitrageDetails/ResultTable.vue";
import MathUtils from "@/service/arbitrage-checker/MathUtils";
import CoinCheck from "@/components/ArbitrageDetails/CoinCheck.vue";
import { CryptoMarketCap } from "@/CurrencyProviders/CryptoMarketCap/CryptoMarketCap";
import firebase from "@/plugins/firebase";
import { CoinGecko } from "@/CurrencyProviders/CoinGecko/CoinGecko";

@Component({
  components: {
    CoinCheck,
    ResultTable,
    ErrorMessage,
    BuySellArbiButtons,
    BoringMarkets,
    ArbiResult,
    ChartZoom,
    DoArbitrage,
    CheckList,
    BalanceSummary,
    OrderTable,
    BalancesTable,
    ChartCardTemplate,
    ArbitrageStepsChart2,
    ArbitrageStepsChart,
    ArbitrageStepsTable,
    ConnectionIcon,
    OrderBookTable,
    OrderBookProgress,
  },
})
export default class ArbitrageDetails extends Vue {
  arbitrageHandler?: ArbitrageHandler;
  arbitrageCalculator = ArbitrageCalculator.instance;
  dataLoader = DataLoader.instance;
  orderCreator = OrderCreator.instance;
  orderCalculator = OrderCalculator.instance;
  transferCreator = TransferCreator.instance;

  buyData: SideData | null = null;
  sellData: SideData | null = null;

  buyBalanceLoading = false;
  sellBalanceLoading = false;
  buyBalanceError: any | null = null;
  sellBalanceError: any | null = null;
  buyTransfersLoading = false;
  sellTransfersLoading = false;
  buyTransfersError: any | null = null;
  sellTransfersError: any | null = null;
  buyOrdersLoading = false;
  sellOrdersLoading = false;
  buyOrdersError: any | null = null;
  sellOrdersError: any | null = null;

  currencyCode: string | null = null;
  vueKeys: string[] = [];
  vueKey: string | null = null;
  item: ArbitrageItem | null = null;
  socketConnected = false;
  pricePrecision = 12;

  arbiResultRemaining: ArbitrageCalculatorResult | null = null;
  arbiResult: ArbitrageCalculatorResult | null = null;
  orderResult: OrderCalculatorResult | null = null;

  arbiAction: ArbiAction = "arbi";

  usedBuyQuote = 0;
  availableBuyQuote = 0;
  zoom: number | null = 5;

  //TODO Once we have other than stable coin quote currencies, we have to convert those to USD everywhere
  displayCurrencyShort = "$";
  quoteDisplayPrecision = 0;

  icons = {
    mdiArrowULeftTop: mdiArrowULeftTop,
    mdiRefresh: mdiRefresh,
  };

  @Watch("item.vueKey")
  async onItemKeyChanged(newKey: string, oldKey: string) {
    if (!newKey || newKey !== oldKey) {
      this.buyData = null;
      this.sellData = null;
    }

    if (this.item) {
      const promises: Promise<void>[] = [this.getBalanceBuy(), this.getBalanceSell()];
      await Promise.allSettled(promises);
      //console.log(this.buyData?.ccxtExchange.ccxt.id, this.buyData?.market);
      //console.log(this.sellData?.ccxtExchange?.ccxt.id, this.sellData?.market);

      const promises2: Promise<void>[] = [this.getBuyOrders(), this.getSellOrders()];
      await Promise.allSettled(promises2);
    }
  }

  @Watch("item")
  @Watch("buyData.usedAccount")
  @Watch("sellData.usedAccount")
  @Watch("usedBuyQuote")
  onItemChanged() {
    this.calculateResults();
  }

  calculateResults() {
    if (this.item && this.buyData !== null && this.sellData !== null) {
      this.arbiResultRemaining = this.arbitrageCalculator.tick(this.item, this.buyData, this.sellData);
      this.availableBuyQuote = Math.floor(this.arbiResultRemaining.sumBuyQuote);
      this.orderResult = this.orderCalculator.calculate(this.buyData, this.sellData);
      const useWithdrawFee = !this.orderResult.hasTransferable;
      this.arbiResult = this.arbitrageCalculator.tick(
        this.item,
        this.buyData,
        this.sellData,
        this.usedBuyQuote,
        Number.MAX_VALUE,
        useWithdrawFee
      );
    }
  }

  async transferAll(marketSide: MarketSide, sideData: SideData) {
    const toAccount = sideData.usedAccount;
    if (marketSide === "quote") {
      this.buyTransfersLoading = true;
      await this.transferCreator.transferAllTo(
        sideData.balance,
        toAccount,
        sideData.accountInfos,
        sideData.ccxtExchange
      );
      this.buyTransfersLoading = false;
      await this.getBalanceBuy();
    } else if (marketSide === "base") {
      this.sellTransfersLoading = true;
      await this.transferCreator.transferAllTo(
        sideData.balance,
        toAccount,
        sideData.accountInfos,
        sideData.ccxtExchange
      );
      this.sellTransfersLoading = false;
      await this.getBalanceSell();
    } else {
      throw new Error("MarketSide is wrong: " + marketSide);
    }
  }

  async getBalanceBuy() {
    if (!this.item) {
      return;
    }
    this.buyBalanceLoading = true;
    this.buyBalanceError = null;
    this.buyData = await this.dataLoader
      .getSideData(this.item.buyExchange, this.item.buySymbol, "buy", "quote", this.buyData?.usedAccount, this.item)
      .catch((error) => {
        this.buyBalanceError = error;
        this.buyBalanceLoading = false;
        throw error;
      });
    this.buyBalanceLoading = false;
    CoinGecko.proxy = "/cors-proxy/";
    CoinGecko.headers = { "x-auth-token": (await firebase.getIdToken()) ?? "" };
    //await CoinGecko.getCurrencies();
    await CoinGecko.loadData("binance", this.buyData.ccxtExchange);
  }

  async getBalanceSell() {
    if (!this.item) {
      return;
    }
    this.sellBalanceLoading = true;
    this.sellBalanceError = null;
    this.sellData = await this.dataLoader
      .getSideData(this.item.sellExchange, this.item.sellSymbol, "sell", "base", this.sellData?.usedAccount, this.item)
      .catch((error) => {
        this.sellBalanceError = error;
        this.sellBalanceLoading = false;
        throw error;
      });
    this.sellBalanceLoading = false;
  }

  async getBuyOrders() {
    this.buyOrdersLoading = true;
    this.buyOrdersError = null;
    if (this.buyData) {
      this.buyData.arbiOrders = await this.dataLoader.fetchOrders("buy", this.buyData).catch((error) => {
        this.buyOrdersError = error;
        this.buyOrdersLoading = false;
        throw error;
      });
    }
    this.buyOrdersLoading = false;
  }

  async getSellOrders() {
    this.sellOrdersLoading = true;
    this.sellOrdersError = null;
    if (this.sellData) {
      this.sellData.arbiOrders = await this.dataLoader.fetchOrders("sell", this.sellData).catch((error) => {
        this.sellOrdersError = error;
        this.sellOrdersLoading = false;
        throw error;
      });
    }
    this.sellOrdersLoading = false;
  }

  doArbitrage() {
    if (this.buyData && this.sellData && this.arbiResult) {
      this.orderCreator.doArbitrage(this.arbiAction, this.buyData, this.sellData, this.arbiResult, this.item);
    }
  }

  arbiRevert(arbiSide: ArbiSide) {
    if (this.buyData && this.sellData && this.orderResult) {
      this.orderCreator.doRevert(arbiSide, this.buyData, this.sellData, this.orderResult, this.item);
    }
  }

  arbiFix(arbiSide: ArbiSide) {
    if (this.buyData && this.sellData && this.orderResult) {
      this.orderCreator.doFix(arbiSide, this.buyData, this.sellData, this.orderResult, this.item);
    }
  }

  async created() {
    const params = this.$router.currentRoute.params;
    this.currencyCode = params.currencyCode;

    this.arbitrageHandler = new ArbitrageHandler();
    await this.arbitrageHandler.start(true);
    this.arbitrageHandler.joinRoom(this.currencyCode + "/USD");

    this.arbitrageHandler.items$.subscribe((items) => {
      this.vueKeys = items.map((item) => item.vueKey);
      this.vueKeys.sort();

      if (this.vueKey) {
        const lastBuyAsks = this.item?.buyAsks;
        const lastBuyBids = this.item?.buyBids;
        const lastSellAsks = this.item?.sellAsks;
        const lastSellBids = this.item?.sellBids;
        this.item = items.find((item) => item.vueKey === this.vueKey) ?? null;
        if (this.item) {
          if (this.item.buyAsks === undefined) {
            this.item.buyAsks = lastBuyAsks ?? [];
          }
          if (this.item.buyBids === undefined) {
            this.item.buyBids = lastBuyBids ?? [];
          }
          if (this.item.sellAsks === undefined) {
            this.item.sellAsks = lastSellAsks ?? [];
          }
          if (this.item.sellBids === undefined) {
            this.item.sellBids = lastSellBids ?? [];
          }
          //if (this.maxCapital === null) {
          //  this.setMaxCapitalAuto();
          //}
        }
      } else {
        this.item = null;
      }
    });

    this.arbitrageHandler.infos$.subscribe((infos) => {
      for (const info of Object.values(infos)) {
        for (const [key, infoData] of Object.entries(info)) {
          if (infoData.count > 35) {
            //NOTE: We allow kucoin, because we can not slow it down
            if (!key.startsWith("kucoin#")) {
              console.error("OrderBook updates are too frequent", key, infoData);
            }
          }
          if (infoData.maxDepth > 500) {
            //NOTE: We allow binance, because of a ccxt bug when limiting orderBook for binance
            if (!key.startsWith("binance#")) {
              console.error("OrderBook depth is too big", key, infoData);
            }
          }
        }
      }
    });

    this.arbitrageHandler.connection$.subscribe((value) => {
      this.socketConnected = value;
    });
  }

  // setMaxCapitalAuto() {
  //   if (!this.item) {
  //     return;
  //   }
  //   this.maxCapital = (this.item.arbitrageStepsShort ?? []).reduce((sum, step) => {
  //     return sum + step[0] * step[2];
  //   }, 0);
  // }

  trunc(value: number) {
    return MathUtils.trunc(value, this.quoteDisplayPrecision ?? 0);
  }

  destroyed() {
    this.arbitrageHandler?.stop();
  }
}
