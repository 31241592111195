import { ArbitrageCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/ArbitrageCalculator";
import { OrderSide, Order, Fee, ArbiSide, OrderType } from "ccxt";
import { ErrorUtils } from "@/Ccxt/ErrorUtils";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { OrderCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCalculator";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";

export interface ArbiOrder {
  uuid: string;
  loading?: boolean;
  order?: Order;
  pendingOrder?: PendingOrder;
  error?: boolean;
  errorMessage?: string;
  promise: Promise<Order>;
}

export interface PendingOrder {
  side: OrderSide;
  amount: number;
  cost: number;
  fee: Fee;
  priceLimit?: number;
}

export type ArbiAction = "buy" | "arbi" | "sell";

export class OrderCreator {
  static instance = new OrderCreator();

  doArbitrage(
    arbiAction: ArbiAction,
    buyData: SideData,
    sellData: SideData,
    arbiResult: ArbitrageCalculatorResult,
    item: ArbitrageItem | null
  ) {
    const createBuyOrder = arbiAction === "arbi" || arbiAction === "buy";
    const createSellOrder = arbiAction === "arbi" || arbiAction === "sell";

    return {
      buyArbiOrder: createBuyOrder ? this.createOrderArbi("buy", buyData, arbiResult, item) : undefined,
      sellArbiOrder: createSellOrder ? this.createOrderArbi("sell", sellData, arbiResult, item) : undefined,
    };
  }

  private createOrderArbi(
    orderSide: OrderSide,
    sideData: SideData,
    arbiResult: ArbitrageCalculatorResult,
    item: ArbitrageItem | null
  ) {
    let amount: number;
    let priceLimit: number;
    let cost: number;

    if (orderSide === "buy") {
      amount = arbiResult.sumBuyBase ?? 0;
      priceLimit = arbiResult.buyPriceLimit ?? 0;
      cost = this.getCost(sideData, amount, item?.buyAsks);
    } else if (orderSide === "sell") {
      amount = arbiResult.sumSellBase ?? 0;
      priceLimit = arbiResult.sellPriceLimit ?? 0;
      cost = this.getCost(sideData, amount, item?.sellBids);
    } else {
      throw new Error("orderSide is wrong in OrderManager: " + orderSide);
    }

    const arbiOrder = this.createLimitOrder(orderSide, sideData, amount, cost, priceLimit);
    if (arbiOrder !== undefined) {
      sideData.arbiOrders.push(arbiOrder);
    }
    return arbiOrder;
  }

  doRevert(
    arbiSide: ArbiSide,
    buyData: SideData,
    sellData: SideData,
    orderResult: OrderCalculatorResult,
    item: ArbitrageItem | null
  ) {
    let amount: number;
    let cost: number;
    let sideData: SideData;
    let orderSide: OrderSide;

    if (arbiSide === "buy") {
      sideData = buyData;
      amount = Math.abs(orderResult.sumBuyBaseDiff ?? 0);
      cost = this.getCost(sideData, amount, item?.buyBids);

      //NOTE: We revert, so sell on buy side
      orderSide = "sell";
    } else if (arbiSide === "sell") {
      sideData = sellData;
      amount = Math.abs(orderResult.sumSellBaseDiff ?? 0) / (1 - sideData.takerFee);
      amount = this.amountToPrecisionEx(sideData, amount, false);
      cost = this.getCost(sideData, amount, item?.sellAsks);

      //NOTE: We revert, so buy on sell side
      orderSide = "buy";
    } else {
      throw new Error("arbiSide is wrong in OrderManager: " + arbiSide);
    }

    const arbiOrder = this.createMarketOrder(orderSide, sideData, amount, cost);
    if (arbiOrder !== undefined) {
      sideData.arbiOrders.push(arbiOrder);
    }
    return arbiOrder;
  }

  doFix(
    arbiSide: ArbiSide,
    buyData: SideData,
    sellData: SideData,
    orderResult: OrderCalculatorResult,
    item: ArbitrageItem | null
  ) {
    let amount;
    let cost;
    let sideData: SideData;
    let orderSide: OrderSide;

    if (arbiSide === "buy") {
      sideData = buyData;
      amount = Math.abs(orderResult.sumBuyBaseDiff ?? 0) / (1 - sideData.takerFee);
      amount = this.amountToPrecisionEx(sideData, amount, false);
      cost = this.getCost(sideData, amount, item?.buyAsks);
      orderSide = "buy";
    } else if (arbiSide === "sell") {
      sideData = sellData;
      amount = Math.abs(orderResult.sumSellBaseDiff ?? 0);
      cost = this.getCost(sideData, amount, item?.sellBids);
      orderSide = "sell";
    } else {
      throw new Error("Wrong arbiSide: " + arbiSide);
    }

    const arbiOrder = this.createMarketOrder(orderSide, sideData, amount, cost);
    if (arbiOrder !== undefined) {
      sideData.arbiOrders.push(arbiOrder);
    }
    return arbiOrder;
  }

  private createLimitOrder(orderSide: OrderSide, sideData: SideData, amount: number, cost: number, priceLimit: number) {
    if (priceLimit === 0) {
      console.error("Can not create an order, priceLimit is 0");
      return;
    }

    return this.createOrder(orderSide, sideData, "limit", amount, cost, priceLimit);
  }

  private createMarketOrder(orderSide: OrderSide, sideData: SideData, amount: number, cost: number) {
    return this.createOrder(orderSide, sideData, "market", amount, cost);
  }

  private createOrder(
    orderSide: OrderSide,
    sideData: SideData,
    orderType: OrderType,
    amount: number,
    cost: number,
    priceLimit?: number
  ) {
    if (amount === 0) {
      console.error("Can not create an order, amount is 0");
      return;
    }

    const ccxtExchange = sideData.ccxtExchange;
    const exchange = sideData.exchange;
    const market = sideData.market;
    const symbol = market.symbol;

    const params: any = {};
    const marginMode = sideData.getUsedMarginMode();
    if (marginMode !== null) {
      params.marginMode = marginMode;
    }

    //TODO Handle BNB fee for binance
    const feeRate = sideData.takerFee;
    const feeCurrency = orderSide === "buy" ? market.base : orderSide === "sell" ? market.quote : "";
    let feeCost = orderSide === "buy" ? amount * feeRate : orderSide === "sell" ? cost * feeRate : 0;
    feeCost = this.feeToPrecisionEx(orderSide, sideData, feeCost, true);
    const fee: Fee = { currency: feeCurrency, cost: feeCost, type: "taker", rate: feeRate };

    let promise: Promise<Order>;
    if (exchange === "binance") {
      params.sideEffectType = "MARGIN_BUY"; //Auto borrow enabled
      //promise = ccxtExchange.ccxt.createOrder(symbol, orderType, orderSide, amount, priceLimit, params);
      promise = Promise.resolve({ side: orderSide, amount, cost, fee, status: "closed" } as Order);
    } else if (exchange === "gateio") {
      params.auto_borrow = true; //Auto borrow enabled
      //promise = ccxtExchange.ccxt.createOrder(symbol, orderType, orderSide, amount, priceLimit, params);
      promise = Promise.resolve({ side: orderSide, amount, cost, fee, status: "closed" } as Order);
    } else {
      //NOTE: Need to check exchanges, whether createOrder:
      //      - supports marginMode param
      //      - auto borrow can be enabled
      throw new Error("Exchange is missing in OrderManager: " + exchange);
    }
    const arbiOrder: ArbiOrder = {
      //@ts-ignore
      uuid: crypto.randomUUID(),
      loading: true,
      promise,
      pendingOrder: {
        side: orderSide,
        amount,
        cost,
        fee,
        priceLimit,
      },
    };
    promise
      .then((order) => {
        arbiOrder.order = order;
        arbiOrder.loading = false;
      })
      .catch((reason) => {
        arbiOrder.error = true;
        arbiOrder.errorMessage = ErrorUtils.parseErrorMessage(reason);
        arbiOrder.loading = false;
      });
    return arbiOrder;
  }

  private getCost(sideData: SideData, amount: number, bidsOrAsks?: [number, number][]) {
    let cost = 0;
    let remainingAmount = amount;
    for (const bidOrAsk of bidsOrAsks ?? []) {
      const price = bidOrAsk[0] ?? 0;
      const amount = bidOrAsk[1] ?? 0;

      const usedAmount = Math.min(amount, remainingAmount);
      if (usedAmount > 0) {
        cost += usedAmount * price;
        remainingAmount -= usedAmount;
      } else {
        break;
      }
    }
    return this.costToPrecisionEx(sideData, cost, true);
  }

  private amountToPrecisionEx(sideData: SideData, amount: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.amountToPrecisionEx(sideData.market.symbol, amount, round);
  }

  private costToPrecisionEx(sideData: SideData, quote: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.costToPrecisionEx(sideData.market.symbol, quote, round);
  }

  private feeToPrecisionEx(orderSide: OrderSide, sideData: SideData, feeCost: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.feeToPrecisionEx(orderSide, sideData.market.symbol, feeCost, round);
  }
}
