
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { Account } from "ccxt";
import { mdiAlertCircleOutline } from "@mdi/js";

@Component({})
export default class MarginNotAvailable extends Vue {
  @Prop()
  sideData!: SideData;
  @Prop()
  account!: Account;

  icons = {
    mdiAlertCircleOutline: mdiAlertCircleOutline,
  };

  checkMarginModeExists() {
    return this.sideData.checkMarginModeExists(this.account);
  }

  get marginMode() {
    return this.sideData.getAccountInfo(this.account)?.marginMode;
  }
}
