
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OrderCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCalculator";
import BalanceSummaryChart from "@/components/ArbitrageDetails/Components/BalanceSummaryChart.vue";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import BalanceSideSummary from "@/components/ArbitrageDetails/Components/BalanceSideSummary.vue";
import ResultTransfer from "@/components/ArbitrageDetails/ResultTable/ResultTransfer.vue";
import ResultTransferExtra from "@/components/ArbitrageDetails/ResultTable/ResultTransferExtra.vue";
import MathUtils from "@/service/arbitrage-checker/MathUtils";

@Component({
  components: { ResultTransferExtra, ResultTransfer, BalanceSideSummary, BalanceSummaryChart },
})
export default class ArbitrageCalculatorResultTable extends Vue {
  @Prop()
  orderResult?: OrderCalculatorResult;
  @Prop()
  buyData?: SideData;
  @Prop()
  sellData?: SideData;
  @Prop()
  quoteDisplayPrecision?: number;

  round(value: number) {
    return MathUtils.round(value, this.quoteDisplayPrecision ?? 0);
  }
}
