
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiWifi, mdiWifiOff } from "@mdi/js";

@Component({})
export default class ConnectionIcon extends Vue {
  @Prop()
  connected?: boolean;

  icons = {
    mdiWifi: mdiWifi,
    mdiWifiOff: mdiWifiOff,
  };
}
