
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OrderCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCalculator";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import MathUtils from "@/service/arbitrage-checker/MathUtils";

@Component({})
export default class ResultTransfer extends Vue {
  @Prop()
  orderResult?: OrderCalculatorResult;
  @Prop()
  buyData?: SideData;
  @Prop()
  sellData?: SideData;
  @Prop()
  quoteDisplayPrecision?: number;

  get buyMarket() {
    return this.buyData?.market;
  }

  get sellMarket() {
    return this.sellData?.market;
  }

  round(value: number) {
    return MathUtils.round(value, this.quoteDisplayPrecision ?? 0);
  }

  get colorClass() {
    const profit = this.orderResult?.profit ?? 0;
    if (profit === 0) {
      return "";
    }
    return profit > 0 ? "green--text" : "red--text";
  }
}
