
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Network, OrderSide } from "ccxt";

@Component({})
export default class NetworkNameListEx extends Vue {
  @Prop()
  networks?: Network[];
  @Prop()
  orderSide?: OrderSide;
}
