
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbitrageStepShort } from "@/service/arbitrage-checker/ArbitrageTypes";
import BoringMarkets from "@/components/Arbitrage/BoringMarkets.vue";

@Component({
  components: { BoringMarkets },
})
export default class ArbitrageStepsTable extends Vue {
  @Prop()
  steps?: ArbitrageStepShort[];
  @Prop()
  pricePrecision?: number;
}
