
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import { Account, MarketSide } from "ccxt";
import BaseBalance from "@/components/ArbitrageDetails/BalanceTable/BaseBalance.vue";
import RestInQuoteBalance from "@/components/ArbitrageDetails/BalanceTable/RestInQuoteBalance.vue";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { mdiWallet } from "@mdi/js";
import IconText from "@/components/General/IconText.vue";
import MarginNotAvailable from "@/components/ArbitrageDetails/Components/MarginNotAvailable.vue";

@Component({
  components: { MarginNotAvailable, IconText, RestInQuoteBalance, BaseBalance },
})
export default class BalancesTable extends Vue {
  @Prop()
  sideData?: SideData;

  icons = {
    mdiWallet: mdiWallet,
  };

  get balance() {
    return this.sideData?.balance;
  }

  get totalBalance() {
    return this.sideData?.balance as MarketBalance;
  }

  get market() {
    return this.sideData?.market;
  }

  get usedAccount() {
    return this.sideData?.usedAccount;
  }

  get crossMaxLeverage() {
    const crossMaxLeverage = this.sideData?.market.crossMaxLeverage;
    return crossMaxLeverage ? crossMaxLeverage + "x" : "";
  }

  get isolatedMaxLeverage() {
    const isolatedMaxLeverage = this.sideData?.market.isolatedMaxLeverage;
    return isolatedMaxLeverage ? isolatedMaxLeverage + "x" : "";
  }

  get accountInfos() {
    return this.sideData?.accountInfos;
  }

  isAccountEnabled(account: Account) {
    return this.accountInfos?.[account] !== undefined && !(this.accountInfos[account]?.disabled ?? false);
  }

  clickAccount(account: Account) {
    if (this.sideData) {
      this.sideData.usedAccount = account;
    }
  }

  getValue(marketBalance: MarketBalance | undefined, side: MarketSide, property: "free" | "borrowed" = "free") {
    if (side === "base") {
      return marketBalance !== undefined && marketBalance.baseBalance !== undefined
        ? marketBalance.baseBalance[property]
        : "-";
    } else if (side === "quote") {
      return marketBalance !== undefined && marketBalance.quoteBalance !== undefined
        ? Math.floor(marketBalance.quoteBalance[property])
        : "-";
    } else {
      return "-";
    }
  }

  getBaseInQuoteValue(marketBalance: MarketBalance | undefined, property: "free" | "borrowed" = "free") {
    return marketBalance !== undefined && marketBalance.baseBalanceInQuote !== undefined
      ? Math.floor(marketBalance.baseBalanceInQuote[property])
      : "-";
  }
}
