import CcxtPrivate from "@/service/CcxtPrivate";

export class ExchangeManager {
  static instance = new ExchangeManager();

  private ccxtExchangePromises = new Map<string, Promise<CcxtPrivate>>();

  async getCcxtExchange(exchange: string) {
    let ccxtExchangePromise = this.ccxtExchangePromises.get(exchange);
    if (ccxtExchangePromise === undefined) {
      const ccxtExchange = new CcxtPrivate(exchange, true);
      ccxtExchangePromise = ccxtExchange.loadMarkets().then(() => ccxtExchange);
      this.ccxtExchangePromises.set(exchange, ccxtExchangePromise);
    }
    return await ccxtExchangePromise;
  }
}
