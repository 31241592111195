
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiCheck } from "@mdi/js";

@Component({})
export default class OrderBookProgress extends Vue {
  @Prop()
  itemDate?: number;

  seconds = 0;
  progress = 100;

  timer?: number;

  icons = {
    mdiCheck: mdiCheck,
  };

  created() {
    this.timer = setInterval(() => {
      this.tick();
    }, 100);
  }

  destroyed() {
    if (this.timer !== undefined) {
      clearInterval(this.timer);
    }
  }

  tick() {
    this.seconds = this.getSeconds();
    this.progress = this.getProgress();
  }

  getSeconds() {
    if (!this.itemDate) {
      return 0;
    }
    const sec = (Date.now() - this.itemDate) / 1000;
    return Math.max(sec, 0);
  }

  getProgress() {
    const max = 30;
    const sec = this.seconds;
    return 100 - (sec / max) * 100;
  }
}
