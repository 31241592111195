import { Account, AccountInfos, Balances, Dictionary, IsolatedBalances, Market, Ticker } from "ccxt";
import {
  BalanceUtils,
  ExchangeMarketBalance,
  MarketBalance,
} from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";

export class BalanceTickerUpdater {
  static instance = new BalanceTickerUpdater();

  updateByTickers(
    accountInfos: AccountInfos,
    exchange: string,
    market: Market,
    balance: ExchangeMarketBalance,
    tickers: Dictionary<Ticker>
  ) {
    for (const [account, accountInfo] of Object.entries(accountInfos)) {
      if (accountInfo.marginMode === "isolated") {
        const isRest = accountInfo.isRestIsolated === true;
        this.updateIsolated(market, balance[account as Account], tickers, isRest);
      } else {
        this.update(market, balance[account as Account], tickers);
      }
    }
    this.updateSum(accountInfos, balance);
  }

  private update(market: Market, marketBalance: MarketBalance | undefined, tickers: Dictionary<Ticker>) {
    if (marketBalance === undefined) {
      return;
    }
    if (marketBalance.balances === undefined || tickers === undefined) {
      marketBalance.restBalanceInQuote = undefined;
      marketBalance.baseBalanceInQuote = undefined;
      marketBalance.quoteBalanceInBase = undefined;
      return;
    }
    const restBalanceInQuote = BalanceUtils.emptyBalance();
    //@ts-ignore
    const balancesInQuote: Balances = {};
    for (const [currencyCode, balance] of Object.entries(marketBalance.balances)) {
      const isBaseOrQuote = [market.base, market.quote].includes(currencyCode);
      if (isBaseOrQuote) {
        continue;
      }
      const symbol = currencyCode + "/" + marketBalance.market.quote;
      const tickerPrice = tickers[symbol]?.last;
      if (tickerPrice !== undefined && balance !== undefined) {
        const balanceInQuote = BalanceUtils.multiply(balance, tickerPrice);
        BalanceUtils.addBalance(restBalanceInQuote, balanceInQuote);
        balancesInQuote[currencyCode] = balanceInQuote;
        //if (balance.free > 0) {
        //  console.log(exchange, type, currencyCode, balance.free, tickerPrice, tickerPrice * balance.free);
        //}
      }
    }
    marketBalance.restBalanceInQuote = restBalanceInQuote;
    marketBalance.balancesInQuote = balancesInQuote;

    const tickerPrice = tickers[market.symbol]?.last;
    if (
      marketBalance.baseBalance !== undefined &&
      marketBalance.quoteBalance !== undefined &&
      tickerPrice !== undefined
    ) {
      marketBalance.baseBalanceInQuote = BalanceUtils.multiply(marketBalance.baseBalance, tickerPrice);
      marketBalance.quoteBalanceInBase = BalanceUtils.multiply(marketBalance.quoteBalance, 1 / tickerPrice);
    }
  }

  private updateIsolated(
    market: Market,
    marketBalance: MarketBalance | undefined,
    tickers: Dictionary<Ticker>,
    isRest: boolean
  ) {
    if (marketBalance === undefined) {
      return;
    }
    if (marketBalance.isolatedBalances === undefined || tickers === undefined) {
      marketBalance.restBalanceInQuote = undefined;
      marketBalance.baseBalanceInQuote = undefined;
      marketBalance.quoteBalanceInBase = undefined;
      return;
    }

    const restBalanceInQuote = BalanceUtils.emptyBalance();
    const isolatedBalancesInQuote: IsolatedBalances = {};
    for (const [isolatedSymbol, isolatedBalance] of Object.entries(marketBalance.isolatedBalances)) {
      if (isolatedSymbol.indexOf("/") === -1 || isolatedSymbol === market.symbol) {
        continue;
      }
      //@ts-ignore
      isolatedBalancesInQuote[isolatedSymbol] = {};
      for (const [currencyCode, balance] of Object.entries(isolatedBalance)) {
        const isBaseOrQuote = [market.base, market.quote].includes(currencyCode);
        if (isBaseOrQuote) {
          continue;
        }
        const symbol = currencyCode + "/" + marketBalance.market.quote;
        const ticker = tickers[symbol];
        let quotePrice = ticker?.last;
        if (quotePrice === undefined && currencyCode === marketBalance.market.quote) {
          quotePrice = 1;
        }
        if (quotePrice !== undefined && balance !== undefined) {
          const balanceInQuote = BalanceUtils.multiply(balance, quotePrice);
          BalanceUtils.addBalance(restBalanceInQuote, balanceInQuote);
          isolatedBalancesInQuote[isolatedSymbol][currencyCode] = balanceInQuote;
          //if (balance.free > 0) {
          //  console.log(exchange, "isolated", currencyCode, balance.free, quotePrice, quotePrice * balance.free);
          //}
        }
      }
    }
    marketBalance.isolatedBalancesInQuote = isolatedBalancesInQuote;
    marketBalance.restBalanceInQuote = isRest ? restBalanceInQuote : undefined;

    const tickerPrice = tickers[market.symbol].last;
    if (
      marketBalance.baseBalance !== undefined &&
      marketBalance.quoteBalance !== undefined &&
      tickerPrice !== undefined
    ) {
      marketBalance.baseBalanceInQuote = BalanceUtils.multiply(marketBalance.baseBalance, tickerPrice);
      marketBalance.quoteBalanceInBase = BalanceUtils.multiply(marketBalance.quoteBalance, 1 / tickerPrice);
    }
  }

  private updateSum(accountInfos: AccountInfos, balance: ExchangeMarketBalance) {
    const accounts = Object.keys(accountInfos);
    const marketBalances: MarketBalance[] = accounts
      .map((account) => balance[account as Account] as MarketBalance)
      .filter((marketBalance) => marketBalance !== undefined);

    balance.restBalanceInQuote = this.getSumBySide("restBalanceInQuote", marketBalances);
    balance.baseBalanceInQuote = this.getSumBySide("baseBalanceInQuote", marketBalances);
    balance.quoteBalanceInBase = this.getSumBySide("quoteBalanceInBase", marketBalances);
  }

  private getSumBySide(
    property: "restBalanceInQuote" | "baseBalanceInQuote" | "quoteBalanceInBase",
    marketBalances: MarketBalance[]
  ) {
    if (marketBalances.length === 0) {
      return;
    }
    const sumBalance = BalanceUtils.emptyBalance();
    for (const marketBalance of marketBalances) {
      BalanceUtils.addBalance(sumBalance, marketBalance[property]);
    }
    return sumBalance;
  }
}
