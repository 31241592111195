
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiAlertCircleOutline } from "@mdi/js";
import OrderStatusIcon from "@/components/ArbitrageDetails/OrderStatusIcon.vue";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import BoringMarkets from "@/components/Arbitrage/BoringMarkets.vue";
import ColoredValue from "@/components/ArbitrageDetails/Components/ColoredValue.vue";

@Component({
  components: { ColoredValue, BoringMarkets, OrderStatusIcon },
})
export default class OrderTable extends Vue {
  @Prop()
  sideData?: SideData;

  icons = {
    mdiAlertCircleOutline: mdiAlertCircleOutline,
  };
}
