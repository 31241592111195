
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { OrderSide } from "ccxt";

@Component({})
export default class ColoredValue extends Vue {
  @Prop()
  side?: OrderSide;

  icons = {
    mdiChevronUp: mdiChevronUp,
    mdiChevronDown: mdiChevronDown,
  };

  get color() {
    if (this.side === undefined) {
      return "";
    }
    return this.side === "buy" ? "green--text" : this.side === "sell" ? "red--text" : "";
  }
}
