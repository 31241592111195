
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BalancesTable extends Vue {
  @Prop()
  icon?: string;
  @Prop()
  size?: number;
  @Prop()
  color?: string;
}
