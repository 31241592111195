
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { ArbitrageStepShort } from "@/service/arbitrage-checker/ArbitrageTypes";
import { ECOption } from "@/plugins/vue-echarts-ts";
import { ECharts } from "echarts";

@Component({})
export default class ArbitrageStepsChart extends Vue {
  @Prop()
  steps?: ArbitrageStepShort[];
  @Prop()
  maxCapital?: number;
  @Prop()
  usedBuyQuote?: number;
  @Prop()
  availableBuyQuote?: number;
  @Prop()
  pricePrecision?: number;

  eChartInstance!: ECharts;

  @Watch("maxCapital", { immediate: true })
  onChangedMaxCapital() {
    //@ts-ignore
    this.options.xAxis.max = this.maxCapital;
  }

  @Watch("availableBuyQuote", { immediate: true })
  onAvailableBuyQuoteChanged() {
    //@ts-ignore
    this.options.series[0].markArea.data[0][1].xAxis = this.availableBuyQuote;
  }

  @Watch("usedBuyQuote", { immediate: true })
  onUsedBuyQuoteChanged() {
    //@ts-ignore
    this.options.series[0].markArea.data[1][1].xAxis = this.usedBuyQuote;
  }

  @Watch("steps")
  onChangedSteps() {
    let sumCapital = 0;
    //let lastSumCapital: number | null = null;
    const data: any[][] = [];
    this.steps?.forEach((step) => {
      const capitalToBuy = step[0] * step[2];
      sumCapital += capitalToBuy;
      const item = [sumCapital.toFixed(), step[0], step[1]];
      //const fakeSumCapital = lastSumCapital !== null ? lastSumCapital + 1 : 0;
      //const fakeItem = [Math.round(fakeSumCapital), item[1], item[2]];
      //data.push(fakeItem);
      if (data.length === 0) {
        data.push([0, item[1], item[2]]);
      }
      data.push(item);
      //lastSumCapital = sumCapital;
    });
    //@ts-ignore
    this.options.dataset.source = data;

    if (this.steps?.length) {
      //@ts-ignore
      this.options.yAxis.min = this.steps[0][0];
      //@ts-ignore
      this.options.yAxis.max = this.steps[0][1];
      //@ts-ignore
      //this.options.xAxis.max = sumCapital.toFixed();
    }
    //this.maxCapital = Math.floor(sumCapital);
  }

  private options: ECOption = {
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "${value}",
        hideOverlap: true,
        fontSize: 10,
        color: "#aaa",
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "#eee",
        },
      },
      animation: false,
      min: 0,
      max: 0,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "#aaa",
        margin: 4,
        fontSize: 11,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "#eee",
        },
      },
      animation: false,
      min: 0,
      max: 0,
    },
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },
    grid: {
      top: 55,
      bottom: 28,
      left: 70,
      right: 30,
    },
    dataset: {
      source: [],
    },
    series: [
      {
        type: "line",
        step: "start",
        name: "ask price",
        encode: {
          x: 0,
          y: 1,
        },
        showSymbol: false,
        animation: false,
        markArea: {
          data: [
            [{ xAxis: 0, itemStyle: { color: "rgba(255, 173, 177, 0.4)" } }, { xAxis: 0 }],
            [{ xAxis: 0, itemStyle: { color: "rgba(255, 173, 177, 0.6)" } }, { xAxis: 0 }],
          ],
        },
      },
      {
        type: "line",
        step: "start",
        name: "bid price",
        encode: {
          x: 0,
          y: 2,
        },
        showSymbol: false,
        animation: false,
      },
    ],
    // tooltip: {
    //   trigger: "axis",
    //   position: function (pt: any) {
    //     return [pt[0], "-15%"];
    //   },
    //   transitionDuration: 0,
    // },
    title: {
      text: "Ask / bid price by capital",
      left: "center",
      top: 12,
      textStyle: {
        fontWeight: "normal",
        color: "#000",
      },
    },
    color: ["#2196F3", "#FFA726"],
  };

  private updateInstance(eChartInstance: ECharts) {
    this.eChartInstance = eChartInstance;
    // console.log("register handler");
    // this.eChartInstance.on("mousedown", (e: object) => {
    //   console.log("echarts mousedown", e);
    // });
    // this.eChartInstance.on("mousemove", (e: object) => {
    //   console.log("echarts mousemove", e);
    // });
    // this.eChartInstance.on("click", (event) => {
    //   console.log("echarts click", event);
    // });
  }
}
