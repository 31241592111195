
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiAlertCircle } from "@mdi/js";

@Component({})
export default class ErrorMessage extends Vue {
  @Prop()
  error?: any;

  icons = {
    mdiAlertCircle: mdiAlertCircle,
  };
}
