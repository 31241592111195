
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { mdiBookOpenOutline, mdiCancel, mdiCheck, mdiClockOutline } from "@mdi/js";

@Component({})
export default class OrderStatusIcon extends Vue {
  @Prop()
  status?: "open" | "closed" | "canceled" | "pending";

  icons = {
    mdiClockOutline: mdiClockOutline,
    mdiCheck: mdiCheck,
    mdiCancel: mdiCancel,
    mdiBookOpenOutline: mdiBookOpenOutline,
  };
}
