import CcxtPrivate from "../../CcxtPrivate";
import { Account, AccountInfos } from "ccxt";
import { ExchangeMarketBalance, MarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";

export class TransferCreator {
  static instance = new TransferCreator();

  async transferAllTo(
    exchangeBalance: ExchangeMarketBalance,
    toAccount: Account,
    accountInfos: AccountInfos,
    ccxtExchange: CcxtPrivate
  ) {
    const marketBalances: MarketBalance[] = Object.entries(accountInfos)
      .filter(([, accountInfo]) => accountInfo.isRestIsolated !== true)
      .map(([account]) => exchangeBalance[account as Account] as MarketBalance)
      .filter((marketBalance) => marketBalance !== undefined);

    const toBalance = marketBalances.find((marketBalance) => marketBalance?.account === toAccount);
    if (toBalance === undefined) {
      return;
    }

    const promises = [];
    for (const fromBalance of marketBalances) {
      const toMarginMode = toBalance.accountInfo?.marginMode;
      if (fromBalance === undefined || (fromBalance === toBalance && toMarginMode !== "isolated")) {
        continue;
      }

      const transferRest = toMarginMode !== "isolated";
      if (fromBalance.balances !== undefined) {
        promises.push(...this.transferAllFrom(fromBalance, toBalance, ccxtExchange, transferRest));
      }
      if (fromBalance.isolatedBalances !== undefined) {
        promises.push(...this.transferAllFromIsolated(fromBalance, toBalance, ccxtExchange, transferRest));
      }
    }
    return await Promise.allSettled(promises);
  }

  private transferAllFrom(
    fromBalance: MarketBalance,
    toBalance: MarketBalance,
    ccxtExchange: CcxtPrivate,
    transferRest: boolean
  ) {
    const toMarket = toBalance.market;
    const toSymbol = toMarket.symbol;
    const toMarketCurrencyCodes = [toMarket.base, toMarket.quote];

    //TODO Handle: "Not a valid margin asset." when transfer spot => cross

    const promises = [];
    for (const [currencyCode, balance] of Object.entries(fromBalance.balances ?? {})) {
      if (!transferRest && !toMarketCurrencyCodes.includes(currencyCode)) {
        continue;
      }
      const amount = balance?.free ?? 0;
      if (amount === undefined || !(amount > 0)) {
        continue;
      }
      const fromAccount = fromBalance.account;
      const toAccount = toBalance.account;
      promises.push(
        this.doTransferAll(currencyCode, amount, fromAccount, toAccount, ccxtExchange, undefined, toSymbol)
      );
    }
    return promises;
  }

  private transferAllFromIsolated(
    fromBalance: MarketBalance,
    toBalance: MarketBalance,
    ccxtExchange: CcxtPrivate,
    transferRest: boolean
  ) {
    const fromAccount = fromBalance.account;
    const toAccount = toBalance.account;
    const toMarket = toBalance.market;
    const toSymbol = toMarket.symbol;
    const toMarketCurrencyCodes = [toMarket.base, toMarket.quote];

    const promises = [];
    for (const [isolatedSymbol, balances] of Object.entries(fromBalance.isolatedBalances ?? {})) {
      if (isolatedSymbol.indexOf("/") === -1 || (fromBalance === toBalance && isolatedSymbol === toMarket.symbol)) {
        continue;
      }
      for (const [currencyCode, balance] of Object.entries(balances ?? {})) {
        if (!transferRest && !toMarketCurrencyCodes.includes(currencyCode)) {
          continue;
        }
        const amount = balance?.free ?? 0;
        if (amount === undefined || !(amount > 0)) {
          continue;
        }
        const fromSymbol = isolatedSymbol;
        promises.push(
          this.doTransferAll(currencyCode, amount, fromAccount, toAccount, ccxtExchange, fromSymbol, toSymbol)
        );
      }
    }
    return promises;
  }

  private async doTransferAll(
    currencyCode: string,
    amount: number,
    fromAccount: Account,
    toAccount: Account,
    ccxtExchange: CcxtPrivate,
    fromSymbol?: string,
    toSymbol?: string
  ) {
    const interAccount = this.getInterAccount(fromAccount, toAccount, ccxtExchange);
    if (interAccount) {
      //NOTE: InterAccount won't be "isolated", so market is not needed to pass for it
      await this.createTransfer(currencyCode, amount, fromAccount, interAccount, ccxtExchange, fromSymbol);
      await this.createTransfer(currencyCode, amount, interAccount, toAccount, ccxtExchange, undefined, toSymbol);
    } else {
      const p = this.createTransfer(currencyCode, amount, fromAccount, toAccount, ccxtExchange, fromSymbol, toSymbol);
      return await p;
    }
  }

  private async createTransfer(
    currencyCode: string,
    amount: number,
    fromAccount: Account,
    toAccount: Account,
    ccxtExchange: CcxtPrivate,
    fromSymbol?: string,
    toSymbol?: string
  ) {
    if (amount === undefined || !(amount > 0)) {
      return;
    }
    fromSymbol = fromAccount === "isolated" ? fromSymbol : undefined;
    toSymbol = toAccount === "isolated" ? toSymbol : undefined;
    console.log(
      "Create transfer",
      currencyCode,
      amount,
      fromAccount,
      toAccount,
      fromSymbol,
      toSymbol,
      ccxtExchange.ccxt.currencies
    );
    if (ccxtExchange.ccxt.transferEx === undefined) {
      throw new Error("TransferEx is not defined for: " + ccxtExchange.ccxt.id);
    }

    return await ccxtExchange.ccxt.transferEx(currencyCode, amount, fromAccount, toAccount, fromSymbol, toSymbol);
  }

  private getInterAccount(fromAccount: Account, toAccount: Account, ccxtExchange: CcxtPrivate) {
    const transferRoutes = ccxtExchange.ccxt.transferRoutes;
    if (transferRoutes === undefined) {
      return;
    }

    let key = fromAccount + "<->" + toAccount;
    let interAccount = transferRoutes[key];
    if (interAccount !== undefined) {
      return interAccount;
    }

    key = toAccount + "<->" + fromAccount;
    interAccount = transferRoutes[key];
    return interAccount;
  }
}
