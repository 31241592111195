
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbiAction } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCreator";

@Component({})
export default class BuySellArbiButtons extends Vue {
  @Prop()
  arbiAction!: ArbiAction;

  types = ["buy", "arbi", "sell"];

  get color() {
    return this.arbiAction === "buy"
      ? "#43A047" //"#0ecb81"
      : this.arbiAction === "arbi"
      ? "#1E88E5" //"#2196f3"
      : this.arbiAction === "sell"
      ? "#E53935" //"#f6465d"
      : "";
  }

  get action() {
    return this.arbiAction;
  }

  set action(value: ArbiAction) {
    this.$emit("update:arbiAction", value);
  }
}
