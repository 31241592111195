import { TieredLeverages, MarginMode, Exchange } from "ccxt";

export class MaxBorrowCalculator {
  static instance = new MaxBorrowCalculator();

  //prettier-ignore
  calculate(totalAsset: number, borrowedAsset: number, marginRisk: number, borrowLimit: number | null, maxLeverage: number,
    marginMode: MarginMode, ccxt: Exchange
  ) {
    if (marginMode === null) {
      return 0;
    }

    const tieredCrossLeverages = ccxt.tieredCrossLeverages;
    const tieredIsolatedLeverages = ccxt.tieredIsolatedLeverages;

    const tieredLeverages =
      marginMode === "cross" ? tieredCrossLeverages : marginMode === "isolated" ? tieredIsolatedLeverages : undefined;

    const borrowed = this.getBorrowed(totalAsset, marginRisk);
    console.log("BORROW COMPARE", borrowedAsset, borrowed);

    let result: number | undefined;
    if (tieredLeverages !== undefined && borrowLimit !== null) {
      result = this.calculateTiered(totalAsset, borrowed, marginRisk, borrowLimit, maxLeverage, tieredLeverages, ccxt.id);
    } else {
      result = this.calculateNormal(totalAsset, borrowed, marginRisk, borrowLimit, maxLeverage);
    }
    if (result === undefined) {
      return;
    }
    console.log(ccxt.id, "result", maxLeverage, totalAsset, result);
    return result;
  }

  //prettier-ignore
  private calculateNormal(totalAsset: number, borrowed: number, marginRisk: number, borrowLimit: number | null, maxLeverage: number) {
    let maxBorrow = this.getMaxBorrow(totalAsset, borrowed, marginRisk, maxLeverage);

    console.log("Total:", totalAsset, ", borrowed:", borrowed, ", maxBorrow:", maxBorrow, ", marginRisk:", marginRisk, ", borrowLimit:", borrowLimit, ", maxLeverage:", maxLeverage); //prettier-ignore

    if (borrowLimit !== null && maxBorrow + borrowed > borrowLimit) {
      maxBorrow = borrowLimit - borrowed;
      console.log("Borrow limit applied, maxBorow:", maxBorrow, ", borrowed:", borrowed, ",borrowLimit:", borrowLimit);
    }
    return maxBorrow;
  }

  private calculateTiered(
    totalAsset: number,
    borrowed: number,
    marginRisk: number,
    borrowLimit: number,
    maxLeverage: number,
    tieredLeverages: TieredLeverages,
    exchange: string
  ) {
    const tierLeverages = tieredLeverages?.[maxLeverage] ?? undefined;
    if (tierLeverages === undefined) {
      console.log("maxLeverage not found for tieredIsolatedLeverages: ", maxLeverage, exchange);
      return;
    }
    const tierCount = tierLeverages.length;
    const tierLength = borrowLimit / tierCount;
    for (let i = 0; i < tierCount; i++) {
      const tierLeverage = tierLeverages[i];
      const nextTierLeverage = tierLeverages[i + 1];
      const tierLimit = (i + 1) * tierLength;

      let maxBorrow = this.getMaxBorrow(totalAsset, borrowed, marginRisk, tierLeverage);
      if (maxBorrow + borrowed <= tierLimit) {
        console.log("tier", tierLeverage, maxBorrow, borrowed, tierLimit);
        return maxBorrow;
      } else if (nextTierLeverage === undefined) {
        console.log("tierLimit1", tierLeverage, maxBorrow, borrowed, tierLimit);
        return tierLimit - borrowed;
      } else {
        maxBorrow = this.getMaxBorrow(totalAsset, borrowed, marginRisk, nextTierLeverage);
        if (maxBorrow + borrowed <= tierLimit) {
          console.log("tierLimit2", tierLeverage, maxBorrow, borrowed, tierLimit);
          return tierLimit - borrowed;
        }
      }
    }
  }

  private getMaxBorrow(totalAsset: number, borrowed: number, marginRisk: number, maxLeverage: number) {
    const minimumMarginRisk = maxLeverage / (maxLeverage - 1);
    if (marginRisk < minimumMarginRisk && marginRisk !== 0) {
      return 0;
    }
    return (totalAsset - borrowed * minimumMarginRisk) / (minimumMarginRisk - 1);
  }

  private getBorrowed(totalAsset: number, marginRisk: number) {
    if (marginRisk === 0) {
      return 0;
    }
    return totalAsset / marginRisk;
  }
}
