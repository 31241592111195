import { ExchangeMarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import { Account, MarketSide } from "ccxt";

export class UsedAccountCalculator {
  static instance = new UsedAccountCalculator();

  private accountsInOrder: Account[] = ["trading", "spot", "cross", "isolated"];

  calculateUsedAccount(balance: ExchangeMarketBalance, marketSide: MarketSide, exchange: string) {
    let resultAccount: Account | undefined;
    let maxBalance = 0;
    for (const account of this.accountsInOrder) {
      if (balance[account] === undefined) {
        continue;
      }
      const balancePostTransfer =
        marketSide === "base"
          ? balance[account]?.baseTotalPostTransfer ?? 0
          : marketSide === "quote"
          ? balance[account]?.quoteTotalPostTransfer ?? 0
          : 0;
      if (resultAccount === undefined || balancePostTransfer > maxBalance) {
        resultAccount = account;
        maxBalance = balancePostTransfer;
      }
    }
    if (resultAccount === undefined) {
      throw new Error("Account can not be selected for: " + exchange);
    }
    return resultAccount;
  }
}
