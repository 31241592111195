
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MarketSide } from "ccxt";
import { mdiAlertCircleOutline } from "@mdi/js";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { ExchangeMarketBalance, MarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import MarginRiskIndicator from "@/components/ArbitrageDetails/MarginRiskIndicator.vue";
import MaxBorrowLimitedByAvailability from "@/components/ArbitrageDetails/Components/MaxBorrowLimitedByAvailability.vue";

@Component({
  components: { MaxBorrowLimitedByAvailability, MarginRiskIndicator },
})
export default class BalanceSummary extends Vue {
  @Prop()
  marketSide!: MarketSide;
  @Prop()
  sideData!: SideData;

  showPostTransfer = false;

  icons = {
    mdiAlertCircleOutline: mdiAlertCircleOutline,
  };

  get market() {
    return this.sideData.market;
  }

  get marginMode() {
    return this.sideData.getUsedMarginMode();
  }

  get balance() {
    return this.sideData.getUsedMarketBalance();
  }

  get maxBorrowable() {
    return this.sideData.getUsedMaxBorrowable();
  }

  get maxBorrowablePostTransfer() {
    return this.sideData.getUsedMaxBorrowablePostTransfer();
  }

  canTransfer() {
    const transfer1 = this.getBalance() !== this.getBalance(true);
    const transfer2 = this.getBalanceOtherSide() !== this.getBalanceOtherSide(true);

    if (this.marginMode === null) {
      return transfer1;
    } else if (this.marginMode === "cross") {
      //TODO Do we need here restInQuote balance also?
      return transfer1 || transfer2;
    } else if (this.marginMode === "isolated") {
      if (this.maxBorrowable?.limitedByAvailability === true) {
        return transfer1;
      } else {
        return transfer1 || transfer2;
      }
    }
  }

  getMarginRisk() {
    return this.balance?.marginRisk;
  }

  couldTransfer() {
    return this.getBalance() !== this.getBalance(true);
  }

  getBalance(postTransfer?: boolean, property: "free" | "borrowed" = "free") {
    const balanceValue = this.getBalanceValue(postTransfer);
    if (balanceValue?.[property] !== undefined) {
      return this.floorQuoteValue(balanceValue[property]) + " " + this.getCurrencyCode();
    } else {
      return "-";
    }
  }

  getBalanceOtherSide(postTransfer?: boolean) {
    const balanceValue = this.getBalanceValueOtherSide(postTransfer);
    if (balanceValue?.free !== undefined) {
      return this.floorQuoteValue(balanceValue.free) + " " + this.getCurrencyCode();
    } else {
      return "-";
    }
  }

  getMaxBorrow() {
    if (this.maxBorrowable?.amount !== undefined) {
      return this.floorQuoteValue(this.maxBorrowable.amount) + " " + this.getCurrencyCode();
    } else {
      return "-";
    }
  }

  getMaxBorrowablePostTransfer() {
    if (this.maxBorrowablePostTransfer !== undefined) {
      return this.floorQuoteValue(this.maxBorrowablePostTransfer) + " " + this.getCurrencyCode();
    } else {
      return "-";
    }
  }

  getBorrowLimit() {
    const borrowLimit = this.maxBorrowable?.limit;
    if (borrowLimit !== undefined) {
      if (borrowLimit === null) {
        return "no limit";
      } else {
        return this.floorQuoteValue(borrowLimit) + " " + this.getCurrencyCode();
      }
    } else {
      return "-";
    }
  }

  getTotal(postTransfer?: boolean) {
    const totalValue = this.getTotalValue(postTransfer);
    if (totalValue !== undefined) {
      return this.floorQuoteValue(totalValue) + " " + this.getCurrencyCode();
    } else {
      return "-";
    }
  }

  private getCurrencyCode() {
    return this.marketSide === "quote" ? this.market.quote : this.marketSide === "base" ? this.market.base : "?";
  }

  private getCurrencyCodeOtherSide() {
    return this.marketSide === "quote" ? this.market.base : this.marketSide === "base" ? this.market.quote : "?";
  }

  private getTotalValue(postTransfer?: boolean) {
    if (!postTransfer) {
      return this.marketSide === "quote"
        ? this.balance?.quoteTotal
        : this.marketSide === "base"
        ? this.balance?.baseTotal
        : undefined;
    } else {
      return this.marketSide === "quote"
        ? this.balance?.quoteTotalPostTransfer
        : this.marketSide === "base"
        ? this.balance?.baseTotalPostTransfer
        : undefined;
    }
  }

  private getBalanceValue(postTransfer?: boolean) {
    const balance: ExchangeMarketBalance | MarketBalance | undefined = !postTransfer
      ? this.balance
      : this.sideData.balance;

    return this.marketSide === "quote"
      ? balance?.quoteBalance
      : this.marketSide === "base"
      ? balance?.baseBalance
      : undefined;
  }

  private getBalanceValueOtherSide(postTransfer?: boolean) {
    const balance: ExchangeMarketBalance | MarketBalance | undefined = !postTransfer
      ? this.balance
      : this.sideData.balance;

    return this.marketSide === "quote"
      ? balance?.baseBalanceInQuote
      : this.marketSide === "base"
      ? balance?.quoteBalanceInBase
      : undefined;
  }

  private floorQuoteValue(value?: number) {
    return this.marketSide === "quote" && value !== undefined ? Math.floor(value) : value;
  }
}
