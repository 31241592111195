var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (
    _vm.sideData !== undefined &&
    _vm.account !== undefined &&
    _vm.marginMode !== undefined &&
    _vm.marginMode !== null &&
    !_vm.checkMarginModeExists()
  )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"orange","size":"22"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircleOutline)+" ")])]}}],null,false,2471428298)},[_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.marginMode))]),_vm._v(" margin is not available for this market. ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }