
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MaxBorrowable } from "ccxt";
import { mdiAlertCircleOutline } from "@mdi/js";

@Component({})
export default class MaxBorrowLimitedByAvailability extends Vue {
  @Prop()
  currencyCode?: string;
  @Prop()
  maxBorrowable?: MaxBorrowable;

  icons = {
    mdiAlertCircleOutline: mdiAlertCircleOutline,
  };
}
