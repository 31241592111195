import { BalanceManager, ExchangeMarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import { ExchangeManager } from "@/service/arbitrage-checker/ArbitrageOrder/ExchangeManager";
import { Account, Dictionary, MarketSide, MaxBorrowableBag, MaxBorrowablePostTransfer, OrderSide, Ticker } from "ccxt";
import { UsedAccountCalculator } from "@/service/arbitrage-checker/ArbitrageOrder/UsedAccountCalculator";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { OrderLoader } from "@/service/arbitrage-checker/ArbitrageOrder/OrderLoader";
import { MaxBorrowablePostTransferCalculator } from "@/service/arbitrage-checker/ArbitrageOrder/MaxBorrowablePostTransferCalculator";
import { BalanceTickerUpdater } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceTickerUpdater";
import { BorrowLimitTickerUpdater } from "@/service/arbitrage-checker/ArbitrageOrder/BorrowLimitTickerUpdater";
import { BalanceTotalUpdater } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceTotalUpdater";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";

export class DataLoader {
  static instance = new DataLoader();

  private balanceManager = BalanceManager.instance;
  private usedAccountCalculator = UsedAccountCalculator.instance;
  private exchangeManager = ExchangeManager.instance;
  private orderLoader = OrderLoader.instance;
  private balanceTickerUpdater = BalanceTickerUpdater.instance;
  private maxBorrowablePostTransferCalculator = MaxBorrowablePostTransferCalculator.instance;
  private borrowLimitTickerUpdater = BorrowLimitTickerUpdater.instance;
  private balanceTotalUpdater = BalanceTotalUpdater.instance;

  async getSideData(
    exchange: string,
    symbol: string,
    orderSide: OrderSide,
    marketSide: MarketSide,
    usedAccount: Account | undefined,
    item: ArbitrageItem
  ) {
    const ccxtExchange = await this.exchangeManager.getCcxtExchange(exchange);
    const market = ccxtExchange.ccxt.markets[symbol];
    const accountInfos = ccxtExchange.ccxt.accountInfos;
    if (accountInfos === undefined) {
      throw new Error("accountInfos is undefined for: " + exchange);
    }

    //NOTE: We can switch off loading balances for faster debugging
    //TODO Change it back
    const loadBalances = false;

    const promises: [
      Promise<ExchangeMarketBalance>,
      Promise<MaxBorrowableBag | undefined>,
      Promise<Dictionary<Ticker> | undefined>,
      Promise<any>
    ] = [
      this.balanceManager.getExchangeMarketBalance(exchange, ccxtExchange, market, accountInfos, loadBalances),
      loadBalances ? ccxtExchange.ccxt.fetchMaxBorrowable(market, marketSide) : Promise.resolve(undefined),
      loadBalances ? ccxtExchange.ccxt.fetchTickers() : Promise.resolve(undefined),
      ccxtExchange.ccxt.fetchTradingFees(),
    ];
    const [balance, maxBorrowableBag, tickers, tradingFees] = await Promise.all(promises);

    const tradingFee = tradingFees[market.symbol];
    const takerFee = tradingFee.taker;

    if (loadBalances) {
      if (tickers !== undefined) {
        this.balanceTickerUpdater.updateByTickers(accountInfos, exchange, market, balance, tickers);
      }
      if (maxBorrowableBag !== undefined && tickers !== undefined) {
        this.borrowLimitTickerUpdater.updateByTickers(
          accountInfos,
          maxBorrowableBag,
          tickers,
          market,
          ccxtExchange.ccxt
        );
      }
    }

    let maxBorrowablePostTransfer: MaxBorrowablePostTransfer | undefined;
    if (loadBalances) {
      if (maxBorrowableBag !== undefined && tickers !== undefined) {
        maxBorrowablePostTransfer = this.maxBorrowablePostTransferCalculator.calculate(
          marketSide,
          accountInfos,
          balance,
          maxBorrowableBag,
          tickers,
          market,
          ccxtExchange.ccxt
        );
      }
    }

    if (loadBalances) {
      if (maxBorrowableBag !== undefined && maxBorrowablePostTransfer !== undefined) {
        this.balanceTotalUpdater.update(balance, maxBorrowableBag, maxBorrowablePostTransfer, accountInfos, marketSide);
      }
    }

    if (usedAccount === undefined) {
      usedAccount = this.usedAccountCalculator.calculateUsedAccount(balance, marketSide, exchange);
    }

    if (loadBalances) {
      await ccxtExchange.ccxt.loadTransactionFeeData(true);
    }

    return new SideData(
      exchange,
      ccxtExchange,
      market,
      balance,
      maxBorrowableBag ?? {},
      maxBorrowablePostTransfer ?? {},
      usedAccount,
      accountInfos,
      tickers ?? {},
      takerFee,
      item.bestArbitrage?.usedNetwork,
      item.withdrawFeeByNetworks,
      item.withdrawFeePercentMap,
      []
    );
  }

  async fetchOrders(orderSide: OrderSide, sideData: SideData) {
    return []; //TODO Change it back
    const arbiOrders = await this.orderLoader.fetchOrders(
      orderSide,
      sideData.exchange,
      sideData.ccxtExchange,
      sideData.market
    );
    // if (orderSide === "sell") {
    //   arbiOrders.push({
    //     //@ts-ignore
    //     order: {
    //       side: "sell",
    //       amount: 200,
    //       cost: 100,
    //       status: "closed",
    //     },
    //     //@ts-ignore
    //     uuid: crypto.randomUUID(),
    //   });
    // }
    return arbiOrders;
  }
}
