
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { ArbitrageStepShort } from "@/service/arbitrage-checker/ArbitrageTypes";
import { ECOption } from "@/plugins/vue-echarts-ts";
import MathUtils from "@/service/arbitrage-checker/MathUtils";

@Component({})
export default class ArbitrageStepsTable extends Vue {
  @Prop()
  steps?: ArbitrageStepShort[];
  @Prop({ default: 5 })
  zoom!: number | null;
  @Prop()
  availableBuyQuote?: number;
  @Prop()
  sumBuyQuote?: number;
  @Prop()
  quoteDisplayPrecision?: number;
  @Prop()
  displayCurrencyShort?: string;

  @Watch("displayCurrencyShort", { immediate: true })
  onDisplayCurrencyShortChanged() {
    //@ts-ignore
    this.options.xAxis.axisLabel.formatter = this.displayCurrencyShort + "{value}";
  }

  @Watch("availableBuyQuote", { immediate: true })
  onAvailableBuyQuoteChanged() {
    //@ts-ignore
    //this.options.series[0].markLine.data[0][0].xAxis = this.availableBuyQuote;
    //@ts-ignore
    //this.options.series[0].markLine.data[0][1].xAxis = this.availableBuyQuote;
  }

  @Watch("sumBuyQuote", { immediate: true })
  @Watch("zoom")
  onSumBuyQuoteChanged() {
    const sumBuyQuote = this.round(this.sumBuyQuote ?? 0);

    //@ts-ignore
    this.options.xAxis.max = this.zoom !== null ? Math.round(sumBuyQuote * this.zoom) : undefined;
    //@ts-ignore
    this.options.series[0].markLine.data[0].xAxis = sumBuyQuote;

    if (this.sumBuyQuote === undefined || this.availableBuyQuote === undefined) {
      //@ts-ignore
      this.options.visualMap.pieces[0].lte = 0; //@ts-ignore
      this.options.visualMap.pieces[1].gte = 0; //@ts-ignore
      this.options.visualMap.pieces[1].lte = 0; //@ts-ignore
      this.options.series[0].markLine.data[0].itemStyle.color = "rgba(25, 118, 210, 1)"; //@ts-ignore
      this.options.series[0].markLine.data[0].label.color = "rgba(25, 118, 210, 1)";
    } else if (this.sumBuyQuote <= this.availableBuyQuote) {
      //@ts-ignore
      this.options.visualMap.pieces[0].lte = sumBuyQuote; //@ts-ignore
      this.options.visualMap.pieces[1].gte = 0; //@ts-ignore
      this.options.visualMap.pieces[1].lte = 0; //@ts-ignore
      this.options.series[0].markLine.data[0].itemStyle.color = "rgba(25, 118, 210, 1)"; //@ts-ignore
      this.options.series[0].markLine.data[0].label.color = "rgba(25, 118, 210, 1)";
    } else {
      //@ts-ignore
      this.options.visualMap.pieces[0].lte = this.availableBuyQuote; //@ts-ignore
      this.options.visualMap.pieces[1].gte = this.availableBuyQuote; //@ts-ignore
      this.options.visualMap.pieces[1].lte = sumBuyQuote; //@ts-ignore
      this.options.series[0].markLine.data[0].itemStyle.color = "rgba(244, 67, 54, 1)"; //@ts-ignore
      this.options.series[0].markLine.data[0].label.color = "rgba(244, 67, 54, 1)";
    }
  }

  @Watch("profitPercent", { immediate: true })
  onProfitPercentChanged() {
    //@ts-ignore
    //this.options.series[0].markLine.data[0].yAxis = this.profitPercent ?? 0;
  }

  @Watch("steps")
  onChanged() {
    let sumCapital = 0;
    //let lastSumCapital: number | null = null;
    const data: any[][] = [];
    this.steps?.forEach((step) => {
      const profit = (step[1] - step[0]) * step[2];
      const capitalToBuy = step[0] * step[2];
      sumCapital += capitalToBuy;
      const profitPercent = (profit / capitalToBuy) * 100;
      const item = [sumCapital.toFixed(), profitPercent.toFixed(2)];
      //const fakeSumCapital = lastSumCapital !== null ? lastSumCapital + 1 : 0;
      //const fakeItem = [Math.round(fakeSumCapital), item[1]];
      //data.push(fakeItem);
      if (data.length === 0) {
        data.push([0, item[1]]);
      }
      data.push(item);
      //lastSumCapital = sumCapital;
    });
    //@ts-ignore
    this.options.dataset.source = data;

    if (this.steps?.length) {
      //@ts-ignore
      //this.options.xAxis.max = sumCapital.toFixed();
    }
  }

  round(value: number) {
    return MathUtils.round(value, this.quoteDisplayPrecision ?? 0);
  }

  private options: ECOption = {
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}",
        hideOverlap: true,
        fontSize: 10,
        color: "#aaa",
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "#eee",
        },
      },
      splitNumber: 4,
      animation: false,
      min: 0,
      max: 0,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}%",
        color: "#aaa",
        margin: 4,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: "#eee",
        },
      },
      animation: false,
    },
    // dataZoom: {
    //   type: "inside",
    //   filterMode: "none",
    //   start: 0,
    //   orient: "horizontal",
    // },
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },
    grid: {
      top: 22,
      bottom: 26,
      left: 47,
      right: 27,
    },
    dataset: {
      source: [],
    },
    series: [
      {
        type: "line",
        step: "start",
        name: "profit (%)",
        encode: {
          x: 0,
          y: 1,
        },
        areaStyle: {},
        lineStyle: {
          color: "rgba(25, 118, 210, 1)",
        },
        showSymbol: false,
        animation: false,
        markLine: {
          symbol: "none",
          emphasis: {
            disabled: true,
          },
          data: [
            {
              xAxis: 0,
              itemStyle: { color: "rgba(25, 118, 210, 1)" },
              lineStyle: {
                type: "dashed",
                width: 2,
              },
              label: { formatter: "{c}", color: "rgba(25, 118, 210, 1)", distance: 4 },
            },
            // [
            //   {
            //     xAxis: 0,
            //     yAxis: 0,
            //     itemStyle: { color: "red" },
            //     lineStyle: {
            //       type: "solid",
            //       width: 1,
            //     },
            //     //label: { formatter: "{c}", color: "#FB8C00", distance: 4 },
            //   },
            //   { xAxis: 0, yAxis: "max" },
            // ],
            // {
            //   yAxis: 0,
            //   lineStyle: {
            //     type: "dashed",
            //     width: 2,
            //     color: "#43A047",
            //   },
            //   label: {
            //     formatter: "{c}%",
            //     position: "insideEndTop",
            //     distance: -2,
            //     fontSize: 20,
            //     backgroundColor: "rgba(67, 160, 71, 1)",
            //     color: "white",
            //     borderRadius: 4,
            //     padding: [4, 8, 0],
            //   },
            // },
          ],
        },
        // markArea: {
        //   data: [
        //     [
        //       { xAxis: 0, yAxis: 0, itemStyle: { color: "rgba(25, 118, 210, 0.5)" } },
        //       { xAxis: 0, yAxis: 0 },
        //     ],
        //   ],
        // },
      },
    ],
    visualMap: {
      type: "piecewise",
      dimension: 0,
      seriesIndex: 0,
      show: false,
      pieces: [
        {
          gte: 0,
          lte: 0,
          color: "rgba(25, 118, 210, 0.4)",
        },
        {
          gte: 0,
          lte: 0,
          color: "rgba(244, 67, 54, 0.5)",
        },
      ],
    },
    // tooltip: {
    //   trigger: "axis",
    //   position: function (pt: any) {
    //     return [pt[0], "-15%"];
    //   },
    //   transitionDuration: 0,
    // },
    // title: {
    //   text: "Profit by capital",
    //   left: "center",
    //   top: 0,
    //   textStyle: {
    //     fontWeight: "normal",
    //     color: "#000",
    //   },
    // },
    color: ["green"],
  };
}
