import { AccountInfos, Dictionary, Exchange, Market, MarketSide, MaxBorrowable, MaxBorrowableBag, Ticker } from "ccxt";

export class BorrowLimitTickerUpdater {
  static instance = new BorrowLimitTickerUpdater();

  updateByTickers(
    accountInfos: AccountInfos,
    maxBorrowableBag: MaxBorrowableBag,
    tickers: Dictionary<Ticker>,
    market: Market,
    ccxt: Exchange
  ) {
    for (const accountInfo of Object.values(accountInfos)) {
      if (accountInfo.marginMode !== null) {
        const tickerPrice = tickers[market.symbol]?.last;
        this.update(ccxt, market, "base", maxBorrowableBag[accountInfo.marginMode], tickerPrice);
      }
    }
  }

  private update(
    ccxt: Exchange,
    market: Market,
    marketSide: MarketSide,
    maxBorrowable?: MaxBorrowable,
    tickerPrice?: number
  ) {
    if (
      marketSide === "base" &&
      maxBorrowable !== undefined &&
      maxBorrowable.limit === undefined &&
      maxBorrowable.quoteLimit !== undefined &&
      tickerPrice !== undefined
    ) {
      if (maxBorrowable.quoteLimit === null) {
        maxBorrowable.limit = null;
      } else {
        const limit = this.amountToPrecisionEx(ccxt, market.symbol, maxBorrowable.quoteLimit / tickerPrice, false);
        //console.log("borrowLimitByQuote", maxBorrowable.quoteLimit, tickerPrice, limit);
        maxBorrowable.limit = limit;
      }
    }
  }

  private amountToPrecisionEx(ccxt: Exchange, symbol: string, amount: number, round: boolean) {
    return ccxt.amountToPrecisionEx(symbol, amount, round);
  }
}
