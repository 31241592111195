
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OrderCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCalculator";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { OrderSide } from "ccxt";

@Component({})
export default class ResultTransferExtra extends Vue {
  @Prop()
  orderResult?: OrderCalculatorResult;
  @Prop()
  buyData?: SideData;
  @Prop()
  sellData?: SideData;

  get buyMarket() {
    return this.buyData?.market;
  }

  get sellMarket() {
    return this.sellData?.market;
  }

  get buyBaseDiff() {
    return this.orderResult?.sumBuyBaseDiff;
  }

  get sellBaseDiff() {
    return this.orderResult?.sumSellBaseDiff;
  }

  revert(orderSide: OrderSide) {
    this.$emit("revert", orderSide);
  }

  fix(orderSide: OrderSide) {
    this.$emit("fix", orderSide);
  }
}
