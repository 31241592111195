
import Vue from "vue";
import Component from "vue-class-component";
import MultiplierSlider from "@/components/ArbitrageDetails/Components/MultiplierSlider.vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: { MultiplierSlider },
})
export default class AmountSelector extends Vue {
  @Prop()
  amount?: number;
  @Prop()
  max?: number;

  baseValues = [10, 100, 1000, 10000, "%"];

  base: number | string = "%";
  multiplier = 10;

  @Watch("base", { immediate: true })
  @Watch("multiplier")
  onChange() {
    if (typeof this.base !== "number") {
      if (this.max !== undefined) {
        this.$emit("update:amount", Math.floor((this.max * this.multiplier) / 100));
      }
    } else {
      this.$emit("update:amount", this.base * this.multiplier);
    }
  }

  @Watch("max")
  onMaxChanged(newValue?: number, oldValue?: number) {
    if (oldValue === undefined && newValue !== undefined) {
      this.onChange();
    }
  }

  get percentBased() {
    return typeof this.base !== "number";
  }
}
