
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import BalanceSummaryChart from "@/components/ArbitrageDetails/Components/BalanceSummaryChart.vue";

@Component({
  components: { BalanceSummaryChart },
})
export default class BalanceSideSummary extends Vue {
  @Prop()
  available?: number;
  @Prop()
  used?: number;
  @Prop()
  currency?: string;
  @Prop()
  reverse?: boolean;

  get flexReverse() {
    return this.reverse ? "flex-row-reverse" : "";
  }

  get textRight() {
    return this.reverse ? "text-right" : "";
  }
}
