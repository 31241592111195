
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import { Market } from "ccxt";

type Result = {
  currencyCode: string;
  free: number;
  freeInQuote?: number;
}[];

type IsolatedResult = {
  isolatedSymbol: string;
  currencyCode: string;
  free: number;
  freeInQuote?: number;
}[];

@Component({
  components: {},
})
export default class RestInQuoteBalance extends Vue {
  @Prop()
  marketBalance?: MarketBalance;
  @Prop()
  market!: Market;
  @Prop()
  isIsolated!: boolean;
  @Prop()
  isTotal!: boolean;

  get restBalanceInQuote() {
    return this.marketBalance?.restBalanceInQuote !== undefined
      ? Math.floor(this.marketBalance.restBalanceInQuote.free)
      : "-";
  }

  get notEmptyBalances() {
    if (this.marketBalance?.balances === undefined) {
      return [] as Result;
    }
    const result: Result = Object.entries(this.marketBalance.balances)
      .map((entry) => {
        const [currencyCode, balance] = entry;
        const freeInQuote = this.marketBalance?.balancesInQuote?.[currencyCode]?.free;
        return {
          currencyCode,
          free: balance?.free,
          freeInQuote: freeInQuote !== undefined ? Math.floor(freeInQuote) : undefined,
        };
      })
      .filter((balance) => {
        const isBaseOrQuote = [this.market.base, this.market.quote].includes(balance.currencyCode);
        return balance.free > 0 && !isBaseOrQuote;
      });

    result.sort((a, b) => {
      return (a.freeInQuote ?? 0) > (b.freeInQuote ?? 0) ? -1 : 1;
    });
    return result;
  }

  get notEmptyBalancesIsolated() {
    if (this.marketBalance?.isolatedBalances === undefined) {
      return [] as IsolatedResult;
    }

    const result: IsolatedResult = [];
    for (const [isolatedSymbol, balances] of Object.entries(this.marketBalance.isolatedBalances)) {
      if (isolatedSymbol.indexOf("/") === -1 || isolatedSymbol === this.market.symbol) {
        continue;
      }
      for (const [currencyCode, balance] of Object.entries(balances)) {
        const isBaseOrQuote = [this.market.base, this.market.quote].includes(currencyCode);
        if (!(balance.free > 0) || isBaseOrQuote) {
          continue;
        }
        const freeInQuote = this.marketBalance?.isolatedBalancesInQuote?.[isolatedSymbol]?.[currencyCode]?.free;
        result.push({
          isolatedSymbol,
          currencyCode,
          free: balance.free,
          freeInQuote: freeInQuote !== undefined ? Math.floor(freeInQuote) : undefined,
        });
      }
    }
    result.sort((a, b) => {
      return (a.freeInQuote ?? 0) > (b.freeInQuote ?? 0) ? -1 : 1;
    });
    return result;
  }
}
