
import Vue from "vue";
import Component from "vue-class-component";
import MultiplierSlider from "@/components/ArbitrageDetails/Components/MultiplierSlider.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: { MultiplierSlider },
})
export default class ChartZoom extends Vue {
  @Prop()
  zoom!: number | null;

  baseValues = [1, 5, 10, 20, 50, null];

  get value() {
    return this.zoom;
  }

  set value(value: number | null) {
    this.$emit("update:zoom", value);
  }
}
