import { MarketSide } from "ccxt";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { ArbitrageStepsCalculator } from "@/service/arbitrage-checker/ArbitrageOrder/ArbitrageStepsCalculator";

export interface ArbitrageCalculatorResult {
  sumBuyBase: number;
  sumBuyQuote: number;
  sumSellBase: number;
  sumSellQuote: number;
  profit: number;
  profitPercent: number;
  buyPriceLimit?: number;
  sellPriceLimit?: number;
}

export class ArbitrageCalculator {
  static instance = new ArbitrageCalculator();

  tick(
    item: ArbitrageItem,
    buyData: SideData,
    sellData: SideData,
    maxBuyQuote?: number,
    maxSellBase?: number,
    useWithdrawFee = true
  ) {
    if (maxBuyQuote === undefined) {
      maxBuyQuote = this.getMaxCapital(buyData, "quote");
    }
    if (maxSellBase === undefined) {
      maxSellBase = this.getMaxCapital(sellData, "base");
    }
    return this.calculate(item, maxBuyQuote, maxSellBase, buyData, sellData, useWithdrawFee);
  }

  private calculate(
    item: ArbitrageItem,
    maxBuyQuote: number,
    maxSellBase: number,
    buyData: SideData,
    sellData: SideData,
    useWithdrawFee: boolean
  ) {
    const calculator = new ArbitrageStepsCalculator(item.arbitrageStepsShort ?? []);

    const withdrawFeeByMaxSellBase = useWithdrawFee ? buyData.calculateWithdrawFeeReverse(maxSellBase) : 0;
    const maxBuyBaseAfterFee = maxSellBase + (withdrawFeeByMaxSellBase ?? 0);
    const maxBuyBaseBySell = maxBuyBaseAfterFee / (1 - buyData.takerFee);

    let sumBuyBase = calculator.calculateBuyBase(maxBuyQuote, maxBuyBaseBySell);
    sumBuyBase = this.amountToPrecisionExMin(buyData, sellData, sumBuyBase, false);

    const { buyQuote, buyPriceLimit } = calculator.calculateBuyQuote(sumBuyBase);
    const sumBuyQuote = this.costToPrecisionEx(buyData, buyQuote, true);
    const buyBaseAfterFee = sumBuyBase * (1 - buyData.takerFee);
    const withdrawFee = useWithdrawFee ? buyData.calculateWithdrawFee(buyBaseAfterFee) : 0;
    let sumSellBase = buyBaseAfterFee - (withdrawFee ?? 0);
    sumSellBase = this.amountToPrecisionEx(sellData, sumSellBase, false);

    const { sellQuote: sellQuoteBeforeFee, sellPriceLimit } = calculator.calculateSellQuote(sumSellBase);
    let sumSellQuote = sellQuoteBeforeFee * (1 - sellData.takerFee);
    sumSellQuote = this.costToPrecisionEx(sellData, sumSellQuote, true);

    const profit = sumSellQuote - sumBuyQuote;
    const profitPercent = sumBuyQuote !== 0 ? (profit / sumBuyQuote) * 100 : 0;

    const result: ArbitrageCalculatorResult = {
      sumBuyBase,
      sumBuyQuote,
      sumSellBase,
      sumSellQuote,
      profit,
      profitPercent,
      buyPriceLimit,
      sellPriceLimit,
    };
    return result;
  }

  private getMaxCapital(sideData: SideData, marketSide: MarketSide) {
    const accountBalance = sideData.getUsedMarketBalance();
    if (accountBalance === undefined) {
      return 0;
    }

    if (marketSide === "base") {
      return accountBalance.baseTotal ?? 0;
    } else if (marketSide === "quote") {
      return accountBalance.quoteTotal ?? 0;
    } else {
      throw new Error("marketSide is wrong: " + marketSide);
    }
  }

  private amountToPrecisionExMin(buyData: SideData, sellData: SideData, amount: number, round: boolean) {
    const amountToBuyPrecision = this.amountToPrecisionEx(buyData, amount, round);
    const amountToSellPrecision = this.amountToPrecisionEx(sellData, amount, round);
    return Math.min(amountToBuyPrecision, amountToSellPrecision);
  }

  private amountToPrecisionEx(sideData: SideData, amount: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.amountToPrecisionEx(sideData.market.symbol, amount, round);
  }

  private costToPrecisionEx(sideData: SideData, quote: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.costToPrecisionEx(sideData.market.symbol, quote, round);
  }

  // private calculate2(
  //   item: ArbitrageItem,
  //   maxBuyQuote: number,
  //   maxSellBase: number,
  //   buyData: SideData,
  //   sellData: SideData
  // ) {
  //   const steps = item.arbitrageStepsShort ?? [];
  //   let remainingBuyQuote = maxBuyQuote;
  //   let remainingSellBase = maxSellBase;
  //   let sumBuyBase = 0;
  //   let sumSellQuote = 0;
  //   let sumSellBase = 0;
  //   for (const step of steps) {
  //     const askPrice = step[0];
  //     const bidPrice = step[1];
  //     const amount = step[2];
  //
  //     //TO-DO Taker fee on both side, also transfer fee!
  //     //console.log(remainingBuyQuote, askPrice, remainingSellBase);
  //     const _maxBuyBase = this.amountToPrecisionExBoth(buyData, sellData, remainingBuyQuote / askPrice, false);
  //     const _maxSellBase = this.amountToPrecisionExBoth(buyData, sellData, remainingSellBase, false);
  //     const maxAmount = this.amountToPrecisionExBoth(buyData, sellData, amount, false);
  //     const arbiBase = Math.min(maxAmount, _maxBuyBase, _maxSellBase);
  //
  //     sumBuyBase += arbiBase;
  //     sumSellQuote += arbiBase * bidPrice;
  //     sumSellBase += arbiBase;
  //     remainingBuyQuote -= arbiBase * askPrice;
  //     remainingSellBase -= arbiBase;
  //
  //     if (arbiBase < maxAmount) {
  //       break;
  //     }
  //   }
  //   sumBuyBase = this.amountToPrecisionEx(buyData, sumBuyBase, false);
  //   sumSellBase = this.amountToPrecisionEx(sellData, sumSellBase, false);
  //
  //   const lastStep = steps.length ? steps[steps.length - 1] : undefined;
  //   const buyPriceLimit = lastStep ? lastStep[0] : 0;
  //   const sellPriceLimit = lastStep ? lastStep[1] : 0;
  //
  //   const sumBuyQuote = maxBuyQuote - remainingBuyQuote;
  //   //sumBuyQuote = Math.floor(sumBuyQuote);
  //   //sumSellQuote = Math.floor(sumSellQuote);
  //   const profit = sumSellQuote - sumBuyQuote; //Math.floor(sumSellQuote - sumBuyQuote);
  //   const profitPercent = sumBuyQuote !== 0 ? (profit / sumBuyQuote) * 100 : 0;
  //   const result: ArbitrageCalculatorResult = {
  //     sumBuyBase,
  //     sumBuyQuote,
  //     sumSellBase,
  //     sumSellQuote,
  //     profit,
  //     profitPercent,
  //     buyPriceLimit,
  //     sellPriceLimit,
  //   };
  //   return result;
  // }

  // private calculateArbitrageItemBuys(buyItem: Item, sellItem: Item) {
  //   const arbitrageItemBuys: ArbitrageItemBuy[] = [];
  //
  //   for (const buy of buyItem.buys) {
  //     const sellValue = this.calculateSellValue(sellItem.bids, buy.amount);
  //     if (sellValue === undefined) {
  //       continue;
  //     }
  //
  //     const value = sellValue * (1 - sellItem.takerFee);
  //     const profit = value - buy.capital;
  //     const profitPercent = Math.round((profit / buy.capital) * 10000) / 100;
  //
  //     arbitrageItemBuys.push({
  //       //amount: buy.amount,
  //       capital: buy.capital,
  //       profit,
  //       profitPercent,
  //     });
  //   }
  //
  //   return arbitrageItemBuys;
  // }
  //
  // private calculateSellValue(bids: [number, number][], amount: number) {
  //   let remainingAmount = amount;
  //   let value = 0;
  //
  //   for (const bid of bids) {
  //     const price = bid[0];
  //     const amount = bid[1];
  //     const usedAmount = Math.min(amount, remainingAmount);
  //
  //     value += usedAmount * price;
  //     remainingAmount -= usedAmount;
  //
  //     if (remainingAmount === 0) {
  //       return value;
  //     }
  //   }
  //   return undefined;
  // }
  //
  // private calculateBuyAmount(asks: [number, number][], capital: number) {
  //   const takerFee = 0.001;
  //
  //   let remainingCapital = capital;
  //   let boughtAmount = 0;
  //
  //   for (const ask of asks) {
  //     const price = ask[0];
  //     const amount = ask[1];
  //     const usedCapital = Math.min(price * amount, remainingCapital);
  //
  //     boughtAmount += usedCapital / price;
  //     remainingCapital -= usedCapital;
  //
  //     if (remainingCapital === 0) {
  //       return boughtAmount * (1 - takerFee);
  //     }
  //   }
  //   return 0;
  // }
}
