
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { ECOption } from "@/plugins/vue-echarts-ts";

@Component({})
export default class BalanceSummaryChart extends Vue {
  @Prop()
  available?: number;
  @Prop()
  used?: number;

  @Watch("available", { immediate: true })
  @Watch("used")
  onChanged() {
    //@ts-ignore
    this.options.series[0].data[0] = this.used;
    //@ts-ignore
    this.options.series[1].data[0] = this.available;
    //@ts-ignore
    this.options.yAxis.max = this.available + this.used;
  }

  private options: ECOption = {
    yAxis: {
      type: "value",
      show: false,
      max: 0,
    },
    xAxis: {
      type: "category",
      show: false,
    },
    textStyle: {
      fontFamily: "Roboto, sans-serif",
    },
    grid: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    series: [
      // {
      //   name: "Access From",
      //   type: "pie",
      //   radius: ["50%", "80%"],
      //   avoidLabelOverlap: false,
      //   label: {
      //     show: true,
      //     position: "inside",
      //   },
      //   emphasis: {
      //     label: {
      //       show: true,
      //       fontSize: "18",
      //     },
      //   },
      //   labelLine: {
      //     show: false,
      //   },
      //   data: [
      //     { value: 1048, name: "Available" },
      //     { value: 735, name: "Used" },
      //   ],
      // },
      {
        type: "bar",
        stack: "total",
        cursor: "default",
        data: [0],
        // label: {
        //   show: true,
        //   position: "bottom",
        //   fontSize: 14,
        // },
        barWidth: "100%",
      },
      {
        type: "bar",
        stack: "total",
        cursor: "default",
        data: [0],
        // label: {
        //   show: true,
        //   position: "bottom",
        //   fontSize: 14,
        // },
      },
    ],
    color: ["#2196f3", "#FF9800"],
  };
}
