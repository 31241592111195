
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class NetworkNameList extends Vue {
  @Prop()
  networks?: string[];
  @Prop()
  selectedNetwork?: string;
}
