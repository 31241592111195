
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BaseBalance extends Vue {
  @Prop()
  baseBalanceInQuote!: string | number;
  @Prop()
  baseBalance!: string | number;
  @Prop()
  quoteCurrencyCode!: string;
}
