export type ArbitrageStepShort = [number, number, number]; //askPrice, bidPrice, amount

export class ArbitrageStepsCalculator {
  constructor(private data: ArbitrageStepShort[]) {}

  calculateBuyBase(maxBuyQuote: number, maxBuyBaseBySell: number) {
    let remainingBuyQuote = maxBuyQuote;
    let remainingBuyBaseBySell = maxBuyBaseBySell;
    let sumBuyBase = 0;
    for (const step of this.data) {
      const askPrice = step[0];
      const amount = step[2];

      const remainingBuyBase = remainingBuyQuote / askPrice;
      const _maxBuyBase = Math.min(amount, remainingBuyBase, remainingBuyBaseBySell);
      if (_maxBuyBase <= 0) {
        break;
      }

      const _maxBuyQuote = _maxBuyBase * askPrice;

      remainingBuyQuote -= _maxBuyQuote;
      remainingBuyBaseBySell -= _maxBuyBase;

      sumBuyBase += _maxBuyBase;
    }

    return sumBuyBase;
  }

  calculateBuyQuote(buyBase: number) {
    let remainingBuyBase = buyBase;
    let buyQuote = 0;
    let buyPriceLimit;
    for (const step of this.data) {
      const askPrice = step[0];
      const amount = step[2];

      const maxBuyBase = Math.min(amount, remainingBuyBase);
      if (maxBuyBase <= 0) {
        break;
      }

      buyPriceLimit = askPrice;

      buyQuote += maxBuyBase * askPrice;
      remainingBuyBase -= maxBuyBase;
    }
    return { buyQuote, buyPriceLimit };
  }

  calculateSellQuote(sellBase: number) {
    let remainingSellBase = sellBase;
    let sellQuote = 0;
    let sellPriceLimit;
    for (const step of this.data) {
      const bidPrice = step[1];
      const amount = step[2];

      const maxSellBase = Math.min(amount, remainingSellBase);
      if (maxSellBase <= 0) {
        break;
      }

      sellPriceLimit = bidPrice;

      sellQuote += maxSellBase * bidPrice;
      remainingSellBase -= maxSellBase;
    }
    return { sellQuote, sellPriceLimit };
  }
}
