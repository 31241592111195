
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import OrderBookProgress from "@/components/ArbitrageDetails/OrderBookProgress.vue";

@Component({
  components: { OrderBookProgress },
})
export default class OrderBookTable extends Vue {
  @Prop()
  itemDate?: number;
  @Prop()
  asksOrBids?: [number, number][];
  @Prop()
  pricePrecision?: number;
}
