import CcxtPrivate from "@/service/CcxtPrivate";
import {
  Account,
  AccountInfo,
  AccountInfos,
  Balance,
  Balances,
  IsolatedBalances,
  Market,
  MarketSide,
  MarginMode,
} from "ccxt";

export class BalanceUtils {
  static safeBalance(balance?: Balance) {
    return balance ?? this.emptyBalance();
  }

  static emptyBalance() {
    const balance: Balance = { free: 0, used: 0, total: 0, borrowed: 0, interest: 0 };
    return balance;
  }

  static multiply(balance: Balance, multiply: number) {
    const result: Balance = {
      free: balance.free * multiply,
      used: balance.used * multiply,
      total: balance.total * multiply,
      borrowed: (balance.borrowed ?? 0) * multiply,
      interest: (balance.interest ?? 0) * multiply,
    };
    return result;
  }

  static addBalance(balance1?: Balance, balance2?: Balance, multiply = 1) {
    if (balance1 !== undefined && balance2 !== undefined) {
      balance1.free += multiply * (balance2?.free ?? 0);
      balance1.used += multiply * (balance2?.used ?? 0);
      balance1.total += multiply * (balance2?.total ?? 0);
      balance1.borrowed += multiply * (balance2?.borrowed ?? 0);
      balance1.interest += multiply * (balance2?.interest ?? 0);
    }
  }
}

export interface MarketBalance {
  account: Account;
  accountInfo?: AccountInfo;
  market: Market;
  baseBalance?: Balance;
  quoteBalance?: Balance;
  restBalanceInQuote?: Balance;
  baseBalanceInQuote?: Balance;
  quoteBalanceInBase?: Balance;
  balances?: Balances;
  balancesInQuote?: Balances;
  isolatedBalances?: IsolatedBalances;
  isolatedBalancesInQuote?: IsolatedBalances;
  marginRisk?: number;
  baseTotal?: number;
  quoteTotal?: number;
  baseTotalPostTransfer?: number;
  quoteTotalPostTransfer?: number;
}

export interface ExchangeMarketBalance {
  spot?: MarketBalance;
  cross?: MarketBalance;
  isolated?: MarketBalance;
  restIsolated?: MarketBalance;
  funding?: MarketBalance;
  trading?: MarketBalance;
  market: Market;
  baseBalance?: Balance;
  quoteBalance?: Balance;
  restBalanceInQuote?: Balance;
  baseBalanceInQuote?: Balance;
  quoteBalanceInBase?: Balance;
}

export class BalanceManager {
  static instance = new BalanceManager();

  async getExchangeMarketBalance(
    exchange: string,
    ccxtExchange: CcxtPrivate,
    market: Market,
    accountInfos: AccountInfos,
    loadBalances: boolean
  ) {
    const promises: Promise<Balances>[] = [];
    for (const accountInfo of Object.values(accountInfos)) {
      if (accountInfo.useBalanceOf !== undefined) {
        continue;
      }
      if (loadBalances) {
        promises.push(ccxtExchange.fetchBalance(accountInfo.fetchBalanceParams));
      }
    }

    if (["binance", "gateio"].includes(exchange)) {
      const [spotResult, crossResult, _isolatedResult] = await Promise.all(promises);

      const isolatedResult = _isolatedResult as unknown as IsolatedBalances;
      // const spotResult = this.getValue(spotPromise);
      // const crossResult = this.getValue(crossPromise);
      // const isolatedResult = this.getValue(isolatedPromise) as unknown as IsolatedBalances;
      //for (const [account, accountInfo] of Object.entries(accountInfos)) {
      //  const spot = this.getMarketBalance(spotResult, market, "spot", accountInfos);
      //}

      const spot = this.getMarketBalance(spotResult, market, "spot", accountInfos, ccxtExchange);
      const cross = this.getMarketBalance(crossResult, market, "cross", accountInfos, ccxtExchange);
      const isolated = this.getMarketBalanceIsolated(isolatedResult, market, "isolated", accountInfos, ccxtExchange);
      const restIsolated = this.getMarketBalanceRestIsolated(
        isolatedResult,
        market,
        "restIsolated",
        accountInfos,
        ccxtExchange
      );

      const baseBalance = this.getSumBalance("base", [spot, cross, isolated, restIsolated]);
      const quoteBalance = this.getSumBalance("quote", [spot, cross, isolated, restIsolated]);
      const balance: ExchangeMarketBalance = {
        spot,
        cross,
        isolated,
        restIsolated,
        market,
        baseBalance,
        quoteBalance,
      };
      return balance;
    } else if (exchange === "okx") {
      const [fundingPromise, tradingPromise] = await Promise.allSettled(promises);

      const fundingResult = this.getValue(fundingPromise);
      const tradingResult = this.getValue(tradingPromise);

      const funding = this.getMarketBalance(fundingResult, market, "funding", accountInfos, ccxtExchange);
      const trading = this.getMarketBalance(tradingResult, market, "trading", accountInfos, ccxtExchange);

      const baseBalance = this.getSumBalance("base", [funding, trading]);
      const quoteBalance = this.getSumBalance("quote", [funding, trading]);
      const balance: ExchangeMarketBalance = {
        funding,
        trading,
        market,
        baseBalance,
        quoteBalance,
      };
      return balance;
    } else {
      throw new Error("Exchange is not implemented in BalanceManager: " + exchange);
    }
  }

  private getSumBalance(side: MarketSide, marketBalances: MarketBalance[]) {
    marketBalances = marketBalances.filter((marketBalance) => marketBalance !== undefined);
    if (marketBalances.length === 0) {
      return;
    }
    const sumBalance = BalanceUtils.emptyBalance();
    for (const marketBalance of marketBalances) {
      if (side === "base") {
        BalanceUtils.addBalance(sumBalance, marketBalance.baseBalance);
      } else if (side === "quote") {
        BalanceUtils.addBalance(sumBalance, marketBalance.quoteBalance);
      } else {
        throw new Error("Wrong side: " + side);
      }
    }
    return sumBalance;
  }

  private getValue(result: PromiseSettledResult<Balances>) {
    return result && result.status === "fulfilled" ? result.value : undefined;
  }

  private getMarketBalance(
    balances: Balances | undefined,
    market: Market,
    account: Account,
    accountInfos: AccountInfos,
    ccxtExchange: CcxtPrivate
  ) {
    const accountInfo = accountInfos[account];
    if (accountInfo === undefined) {
      throw new Error("Account info is not defined for exchange " + ccxtExchange.ccxt.id + ": " + account);
    }
    const marketBalance: MarketBalance = {
      account,
      accountInfo,
      market,
      baseBalance: BalanceUtils.safeBalance(balances?.[market.base]),
      quoteBalance: BalanceUtils.safeBalance(balances?.[market.quote]),
      balances,
      marginRisk: this.getMarginRisk(balances, undefined, market, accountInfo.marginMode, ccxtExchange),
    };
    return marketBalance;
  }

  private getMarketBalanceIsolated(
    isolatedBalances: IsolatedBalances | undefined,
    market: Market,
    account: Account,
    accountInfos: AccountInfos,
    ccxtExchange: CcxtPrivate
  ) {
    const accountInfo = accountInfos?.[account];
    if (accountInfo === undefined) {
      throw new Error("Account info is not defined for exchange " + ccxtExchange.ccxt.id + ": " + account);
    }
    const marketBalance: MarketBalance = {
      account,
      accountInfo,
      market,
      baseBalance: BalanceUtils.safeBalance(isolatedBalances?.[market.symbol]?.[market.base]),
      quoteBalance: BalanceUtils.safeBalance(isolatedBalances?.[market.symbol]?.[market.quote]),
      isolatedBalances,
      marginRisk: this.getMarginRisk(undefined, isolatedBalances, market, accountInfo.marginMode, ccxtExchange),
    };
    return marketBalance;
  }

  private getMarketBalanceRestIsolated(
    isolatedBalances: IsolatedBalances | undefined,
    market: Market,
    account: Account,
    accountInfos: AccountInfos,
    ccxtExchange: CcxtPrivate
  ) {
    const baseBalance: Balance = BalanceUtils.emptyBalance();
    const quoteBalance: Balance = BalanceUtils.emptyBalance();
    for (const [symbol, balances] of Object.entries(isolatedBalances ?? {})) {
      if (symbol === market.symbol || symbol.indexOf("/") === -1) {
        continue;
      }
      BalanceUtils.addBalance(baseBalance, balances[market.base]);
      BalanceUtils.addBalance(quoteBalance, balances[market.quote]);
    }
    const accountInfo = accountInfos[account];
    if (accountInfo === undefined) {
      throw new Error("Account info is not defined for exchange " + ccxtExchange.ccxt.id + ": " + account);
    }
    const marketBalance: MarketBalance = {
      account,
      accountInfo,
      market,
      baseBalance,
      quoteBalance,
      isolatedBalances,
      marginRisk: undefined,
    };
    return marketBalance;
  }

  private getMarginRisk(
    balances: Balances | undefined,
    isolatedBalances: IsolatedBalances | undefined,
    market: Market,
    marginMode: MarginMode,
    ccxtExchange: CcxtPrivate
  ) {
    if (marginMode === "cross") {
      const getter = ccxtExchange.ccxt.getCrossMarginRisk;
      if (getter === undefined) {
        throw new Error("Exchange missing getCrossMarginRisk: " + ccxtExchange.ccxt.id);
      }
      if (balances === undefined) {
        return;
      }
      return ccxtExchange.ccxt.getCrossMarginRisk(balances, market.quote);
    } else if (marginMode === "isolated") {
      const getter = ccxtExchange.ccxt.getIsolatedMarginRisk;
      if (getter === undefined) {
        throw new Error("Exchange missing getIsolatedMarginRisk: " + ccxtExchange.ccxt.id);
      }
      if (isolatedBalances === undefined) {
        return;
      }
      return ccxtExchange.ccxt.getIsolatedMarginRisk(isolatedBalances, market.id, market.quote);
    } else if (marginMode === null) {
      return 0;
    } else {
      throw new Error("MarginMode is wrong: " + marginMode);
    }
  }
}
