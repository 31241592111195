import { Market, Order, OrderSide, Trade } from "ccxt";
import { ArbiOrder } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCreator";
import CcxtPrivate from "@/service/CcxtPrivate";

export class OrderLoader {
  static instance = new OrderLoader();

  async fetchOrders(orderSide: OrderSide, exchange: string, ccxtExchange: CcxtPrivate, market: Market) {
    let orders: Order[];
    if (exchange === "gateio") {
      //TODO: Looks like cross margin is not supported by ccxt for gateio (account parameter needs to be sent)
      //NOTE: Isolated margin orders are included in the queries below
      //NOTE: Maximum 30 days can be queried at once, if "from" and "to" are not send, only 7 days will be applied
      const to = Math.trunc(Date.now() / 1000);
      const from = (to - 86400 * 30) * 1000;
      const closedOrders = await ccxtExchange.ccxt.fetchClosedOrders(market.symbol, from, undefined, {
        to: to,
      });
      const openOrders = await ccxtExchange.ccxt.fetchOpenOrders(market.symbol);
      orders = closedOrders.concat(openOrders);
    } else if (exchange === "binance") {
      const spotOrders = await ccxtExchange.ccxt.fetchOrders(market.symbol);
      const spotTrades = spotOrders.length > 0 ? await ccxtExchange.ccxt.fetchMyTrades(market.symbol) : [];
      let crossOrders: Order[] = [];
      let crossTrades: Trade[] = [];
      if (market.crossMargin) {
        crossOrders = await ccxtExchange.ccxt.fetchOrders(market.symbol, undefined, undefined, {
          marginMode: "cross",
        });
        crossTrades =
          crossOrders.length > 0
            ? await ccxtExchange.ccxt.fetchMyTrades(market.symbol, undefined, undefined, {
                marginMode: "cross",
              })
            : [];
      }
      let isolatedOrders: Order[] = [];
      let isolatedTrades: Trade[] = [];
      if (market.isolatedMargin) {
        isolatedOrders = await ccxtExchange.ccxt.fetchOrders(market.symbol, undefined, undefined, {
          marginMode: "isolated",
        });
        isolatedTrades =
          isolatedOrders.length > 0
            ? await ccxtExchange.ccxt.fetchMyTrades(market.symbol, undefined, undefined, {
                marginMode: "isolated",
              })
            : [];
      }
      this.mergeTrades(spotOrders, spotTrades);
      this.mergeTrades(crossOrders, crossTrades);
      this.mergeTrades(isolatedOrders, isolatedTrades);

      orders = spotOrders.concat(crossOrders.concat(isolatedOrders));
    } else if (exchange === "okx") {
      const closedOrdersSpot = await ccxtExchange.ccxt.fetchClosedOrders(market.symbol);
      const closedOrdersMargin = await ccxtExchange.ccxt.fetchClosedOrders(market.symbol, undefined, undefined, {
        type: "margin", //TODO Check whether both cross and isolated margin orders are coming back
      });
      const openOrders = await ccxtExchange.ccxt.fetchOpenOrders(market.symbol);
      orders = closedOrdersSpot.concat(closedOrdersMargin.concat(openOrders));
    } else {
      throw new Error("Exchange is missing in OrderLoader: " + exchange);
    }

    const arbiOrders: ArbiOrder[] = orders.map((order) => {
      return {
        order: order,
        //@ts-ignore
        uuid: crypto.randomUUID(),
        promise: Promise.resolve(order),
      };
    });
    return arbiOrders;
  }

  mergeTrades(orders: Order[], trades: Trade[]) {
    for (const order of orders) {
      order.trades = trades.filter((trade) => trade.order === order.id);
    }
  }
}
