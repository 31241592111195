
import Vue from "vue";
import Component from "vue-class-component";
import { mdiCheckboxMarked, mdiHelpBox, mdiMinusBox } from "@mdi/js";
import { ArbitrageItem } from "@/service/arbitrage-checker/ArbitrageTypes";
import { Prop } from "vue-property-decorator";
import { Network } from "ccxt";
import NetworkNameList from "@/components/ArbitrageDetails/CheckList/NetworkNameList.vue";
import NetworkNameListEx from "@/components/ArbitrageDetails/CheckList/NetworkNameListEx.vue";
import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";

@Component({
  components: { NetworkNameListEx, NetworkNameList },
})
export default class CheckList extends Vue {
  @Prop()
  item!: ArbitrageItem;
  @Prop()
  buyData?: SideData;
  @Prop()
  sellData?: SideData;

  icons = {
    mdiCheckboxMarked: mdiCheckboxMarked,
    mdiMinusBox: mdiMinusBox,
    mdiHelpBox: mdiHelpBox,
  };

  get withdrawFeeKnown() {
    const usedNetwork = this.item.bestArbitrage?.usedNetwork;
    return usedNetwork !== undefined && this.item.withdrawFeeByNetworks?.[usedNetwork] !== undefined;
  }

  get withdrawFeePercentKnown() {
    return this.item.withdrawFeePercentMap?.maxValue !== undefined;
  }

  getOtherNetworks(networks?: Network[]) {
    return (networks ?? []).filter((network) => !this.item.commonNetworks?.includes(network.network));
  }
}
