import { render, staticRenderFns } from "./BalanceSummaryChart.vue?vue&type=template&id=60b15bf4&scoped=true&"
import script from "./BalanceSummaryChart.vue?vue&type=script&lang=ts&"
export * from "./BalanceSummaryChart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b15bf4",
  null
  
)

export default component.exports