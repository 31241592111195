
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { ArbitrageCalculatorResult } from "@/service/arbitrage-checker/ArbitrageOrder/ArbitrageCalculator";
import { mdiChevronDoubleRight, mdiMenuDown, mdiMenuUp } from "@mdi/js";
import { Market } from "ccxt";
import MathUtils from "@/service/arbitrage-checker/MathUtils";

@Component({})
export default class DoArbitrage extends Vue {
  @Prop()
  arbiResult?: ArbitrageCalculatorResult;
  @Prop()
  quoteDisplayPrecision?: number;
  @Prop()
  buyMarket!: Market | null;
  @Prop()
  sellMarket!: Market | null;

  icons = {
    mdiChevronDoubleRight: mdiChevronDoubleRight,
    mdiMenuUp: mdiMenuUp,
    mdiMenuDown: mdiMenuDown,
  };

  get sumBuyQuote() {
    return this.arbiResult?.sumBuyQuote ?? 0;
  }

  get profitPercent() {
    return this.arbiResult?.profitPercent ?? 0;
  }

  get profit() {
    return this.arbiResult?.profit ?? 0;
  }

  get hasProfit() {
    return this.profitPercent > 0 || this.profit > 0;
  }

  round(value: number) {
    return MathUtils.round(value, this.quoteDisplayPrecision ?? 0);
  }

  getIcon(value: number) {
    if (value >= 0) {
      return this.icons.mdiMenuUp;
    } else {
      return this.icons.mdiMenuDown;
    }
  }

  getColorClass(value: number) {
    if (value >= 0) {
      return "green--text";
    } else {
      return "red--text";
    }
  }
}
