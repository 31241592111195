
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MathUtils from "@/service/MathUtils";
import { mdiCheck } from "@mdi/js";

@Component({})
export default class MarginRiskIndicator extends Vue {
  @Prop()
  marginRisk?: number;
  @Prop({ default: 40 })
  size?: number;

  icons = {
    mdiCheck: mdiCheck,
  };

  get level() {
    if (this.marginRisk === undefined || this.marginRisk === 0) {
      return undefined;
    } else if (this.marginRisk >= 1.5) {
      return "low";
    } else if (this.marginRisk >= 1.2) {
      return "medium";
    } else {
      return "high";
    }
  }

  get color() {
    if (this.marginRisk === undefined) {
      return undefined;
    } else if (this.marginRisk === 0) {
      return "#43A047";
    } else if (this.marginRisk >= 1.5) {
      return "#0ecb81";
    } else if (this.marginRisk >= 1.2) {
      return "#f0b90b";
    } else {
      return "#f6465d";
    }
  }

  get label() {
    if (this.marginRisk === undefined || this.marginRisk === 0) {
      return "-";
    }
    return MathUtils.round(this.marginRisk, 2);
  }
}
