import { SideData } from "@/service/arbitrage-checker/ArbitrageOrder/SideData";
import { Fee, Order, ArbiSide, OrderSide } from "ccxt";

export interface OrderCalculatorResult {
  sumCommonBuyBase: number;
  sumCommonSellBase: number;
  sumCommonBuyQuote: number;
  sumCommonSellQuote: number;
  sumBuyAmount: BaseQuote;
  sumBuyFee: BaseQuote;
  sumBuyBaseDiff: number;
  sumSellAmount: BaseQuote;
  sumSellFee: BaseQuote;
  sumSellBaseDiff: number;
  buyAveragePrice?: number;
  sellAveragePrice?: number;
  withdrawFee: number;
  hasTransferable: boolean;
  hasExcessAmount: boolean;
  profit: number;
  profitPercent: number;
}

export interface BaseQuote {
  base: number;
  quote: number;
}

export class OrderCalculator {
  static instance = new OrderCalculator();

  calculate(buyData: SideData, sellData: SideData) {
    const { sumAmount: sumBuyAmount, sumFee: sumBuyFee } = this.doCalculateSide("buy", buyData);
    const { sumAmount: sumSellAmount, sumFee: sumSellFee } = this.doCalculateSide("sell", sellData);

    const buyAveragePrice = sumBuyAmount.base !== 0 ? sumBuyAmount.quote / sumBuyAmount.base : undefined;
    const sellAveragePrice = sumSellAmount.base !== 0 ? sumSellAmount.quote / sumSellAmount.base : undefined;

    const withdrawFee = buyData.calculateWithdrawFee(sumBuyAmount.base) ?? 0;
    let sumCommonBuyBase;
    let sumCommonSellBase;
    if (sumBuyAmount.base - withdrawFee <= 0 || sumSellAmount.base <= 0) {
      sumCommonBuyBase = 0;
      sumCommonSellBase = 0;
    } else if (sumBuyAmount.base - withdrawFee <= sumSellAmount.base) {
      sumCommonBuyBase = sumBuyAmount.base;
      sumCommonSellBase = sumBuyAmount.base - withdrawFee;
    } else {
      const withdrawFeeBySumSellAmount = buyData.calculateWithdrawFeeReverse(sumSellAmount.base) ?? 0;
      sumCommonBuyBase = sumSellAmount.base + withdrawFeeBySumSellAmount;
      sumCommonSellBase = sumSellAmount.base;
    }
    const hasTransferable = sumCommonBuyBase !== 0 && sumCommonSellBase !== 0;
    //let sumCommonBase = Math.min(sumBuyAmount.base, sumSellAmount.base);
    //if (sumCommonBase < 0) {
    //  sumCommonBase = 0;
    //}
    let sumCommonBuyQuote = buyAveragePrice !== undefined ? sumCommonBuyBase * buyAveragePrice : 0;
    let sumCommonSellQuote = sellAveragePrice !== undefined ? sumCommonSellBase * sellAveragePrice : 0;
    sumCommonBuyQuote = this.quoteToPrecisionEx(buyData, sumCommonBuyQuote, true);
    sumCommonSellQuote = this.quoteToPrecisionEx(sellData, sumCommonSellQuote, true);

    let profit = sumCommonSellQuote - sumCommonBuyQuote;
    const profitPercent = sumCommonBuyQuote !== 0 ? (profit / sumCommonBuyQuote) * 100 : 0;
    profit = this.quoteToPrecisionExMax(buyData, sellData, profit, true);

    let sumBuyBaseDiff = sumBuyAmount.base - sumSellAmount.base - withdrawFee;
    if (sumSellAmount.base <= 0) {
      sumBuyBaseDiff = sumBuyAmount.base;
    }
    sumBuyBaseDiff = this.amountToPrecisionEx(buyData, sumBuyBaseDiff, false);

    let sumSellBaseDiff = sumSellAmount.base - sumBuyAmount.base + withdrawFee;
    if (sumBuyAmount.base <= withdrawFee) {
      sumSellBaseDiff = sumSellAmount.base;
    }
    sumSellBaseDiff = this.amountToPrecisionEx(sellData, sumSellBaseDiff, false);

    const hasExcessAmount = sumBuyBaseDiff !== 0 || sumSellBaseDiff !== 0;

    const result: OrderCalculatorResult = {
      sumCommonBuyBase,
      sumCommonSellBase,
      sumCommonBuyQuote,
      sumCommonSellQuote,
      sumBuyAmount,
      sumBuyFee,
      sumBuyBaseDiff,
      sumSellAmount,
      sumSellFee,
      sumSellBaseDiff,
      buyAveragePrice,
      sellAveragePrice,
      withdrawFee,
      hasTransferable,
      hasExcessAmount,
      profit,
      profitPercent,
    };
    return result;
  }

  private doCalculateSide(arbiSide: ArbiSide, sideData: SideData) {
    const sumAmount: BaseQuote = { base: 0, quote: 0 };
    const sumFee: BaseQuote = { base: 0, quote: 0 };

    for (const arbiOrder of sideData.arbiOrders) {
      const order = arbiOrder.order;
      if (order === undefined) {
        continue;
      }

      if (order.side === "buy") {
        sumAmount.base += order.amount ?? 0;
        sumAmount.quote -= order.cost ?? 0;
      }
      if (order.side === "sell") {
        sumAmount.base -= order.amount ?? 0;
        sumAmount.quote += order.cost ?? 0;
      }

      const fees = this.getFees(order);
      for (const fee of fees) {
        if (fee.currency === sideData.market.base) {
          sumAmount.base -= fee.cost ?? 0;
          sumFee.base += fee.cost ?? 0;
        }
        if (fee.currency === sideData.market.quote) {
          sumAmount.quote -= fee.cost ?? 0;
          sumFee.quote += fee.cost ?? 0;
        }
        //TODO Do we calculate somewhere when fee currency is different from the market? Like BNB for binance?
      }
    }
    if (arbiSide === "buy") {
      sumAmount.quote *= -1;
    } else if (arbiSide === "sell") {
      sumAmount.base *= -1;
    } else {
      throw new Error("Wrong arbiSide: " + arbiSide);
    }

    sumAmount.base = this.baseToPrecisionEx(sideData, sumAmount.base, true);
    sumAmount.quote = this.quoteToPrecisionEx(sideData, sumAmount.quote, true);

    sumFee.base = this.feeToPrecisionEx("buy", sideData, sumFee.base, true);
    sumFee.quote = this.feeToPrecisionEx("sell", sideData, sumFee.quote, true);

    return { sumAmount, sumFee };
  }

  private getFees(order: Order) {
    const fees: Fee[] = [];
    if (order.fee !== undefined) {
      fees.push(order.fee);
    } else if (order.trades !== undefined) {
      fees.push(...order.trades.map((trade) => trade.fee));
    }
    return fees;
  }

  private quoteToPrecisionExMax(buyData: SideData, sellData: SideData, cost: number, round: boolean) {
    const quoteToBuyPrecision = this.quoteToPrecisionEx(buyData, cost, round);
    const quoteToSellPrecision = this.quoteToPrecisionEx(sellData, cost, round);
    return Math.max(quoteToBuyPrecision, quoteToSellPrecision);
  }

  private baseToPrecisionEx(sideData: SideData, baseValue: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.baseToPrecisionEx(sideData.market.symbol, baseValue, round);
  }

  private quoteToPrecisionEx(sideData: SideData, quoteValue: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.quoteToPrecisionEx(sideData.market.symbol, quoteValue, round);
  }

  private amountToPrecisionEx(sideData: SideData, amount: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.amountToPrecisionEx(sideData.market.symbol, amount, round);
  }

  private feeToPrecisionEx(orderSide: OrderSide, sideData: SideData, feeCost: number, round: boolean) {
    return sideData.ccxtExchange.ccxt.feeToPrecisionEx(orderSide, sideData.market.symbol, feeCost, round);
  }

  //private amountToPrecision(sideData: SideData, amount: number) {
  //  return sideData.ccxtExchange.ccxt.amountToPrecision(sideData.market.symbol, amount);
  //}
}
