
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MultiplierSlider from "@/components/ArbitrageDetails/Components/MultiplierSlider.vue";
import { Market } from "ccxt";
import AmountSelector from "@/components/ArbitrageDetails/Components/AmountSelector.vue";
import { ArbiAction } from "@/service/arbitrage-checker/ArbitrageOrder/OrderCreator";

@Component({
  components: { AmountSelector, MultiplierSlider },
})
export default class DoArbitrage extends Vue {
  @Prop()
  usedBuyQuote!: number;
  @Prop()
  max?: number;
  @Prop()
  arbiAction!: ArbiAction;
  @Prop()
  buyMarket!: Market | null;

  get value() {
    return this.usedBuyQuote;
  }
  set value(value: number) {
    this.$emit("update:usedBuyQuote", value);
  }

  get buttonColor() {
    if (this.arbiAction === "arbi") {
      return "blue darken-1";
    } else if (this.arbiAction === "buy") {
      return "green darken-1";
    } else if (this.arbiAction === "sell") {
      return "red darken-1";
    } else {
      return "";
    }
  }

  get buttonLabel() {
    if (this.arbiAction === "arbi") {
      return "Do Arbitrage";
    } else if (this.arbiAction === "buy") {
      return "BUY";
    } else if (this.arbiAction === "sell") {
      return "SELL";
    } else {
      return "";
    }
  }
}
