
import Vue from "vue";
import Component from "vue-class-component";
import { mdiChartTimelineVariant } from "@mdi/js";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BoringMarkets extends Vue {
  @Prop()
  label?: string;

  icons = {
    mdiChartTimelineVariant: mdiChartTimelineVariant,
  };
}
