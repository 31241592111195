
import Vue from "vue";
import Component from "vue-class-component";
import BoringMarkets from "@/components/Arbitrage/BoringMarkets.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: { BoringMarkets },
})
export default class ChartCardTemplate extends Vue {
  @Prop()
  hasData?: boolean;
}
