import { ExchangeMarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import {
  AccountInfos,
  MaxBorrowableBag,
  MaxBorrowablePostTransfer,
  Account,
  Market,
  Exchange,
  MarketSide,
  Dictionary,
  Ticker,
  MarginMode,
  AccountInfo,
} from "ccxt";
import { MaxBorrowCalculator } from "@/service/arbitrage-checker/ArbitrageOrder/MaxBorrowCalculator";

export class MaxBorrowablePostTransferCalculator {
  static instance = new MaxBorrowablePostTransferCalculator();
  private maxBorrowCalculator = MaxBorrowCalculator.instance;

  calculate(
    marketSide: MarketSide,
    accountInfos: AccountInfos,
    balance: ExchangeMarketBalance,
    maxBorrowableBag: MaxBorrowableBag,
    tickers: Dictionary<Ticker>,
    market: Market,
    ccxt: Exchange
  ) {
    const result: MaxBorrowablePostTransfer = {};
    for (const [account, accountInfo] of Object.entries(accountInfos)) {
      const marginMode = accountInfo.marginMode;
      if (marginMode === null || accountInfo.isRestIsolated === true) {
        continue;
      }
      const limitedByAvailability = maxBorrowableBag[marginMode]?.limitedByAvailability;
      if (limitedByAvailability === true) {
        result[account as Account] = 0;
        continue;
      }
      const borrowLimit = maxBorrowableBag[marginMode]?.limit;
      if (borrowLimit === undefined) {
        continue;
      }
      const accountBalance = balance[account as Account];
      if (accountBalance === undefined) {
        continue;
      }
      const marginRisk = accountBalance.marginRisk;
      if (marginRisk === undefined) {
        continue;
      }
      const tickerPrice = tickers[market.symbol]?.last;
      if (tickerPrice === undefined) {
        continue;
      }

      //Total asset means "free" + "locked" (locked means it's locked by an open order)
      const totalAssetInQuote = this.getTotalAssetInQuote(balance, accountInfo);
      const totalAssetInBase = this.getTotalAssetInBase(balance);
      const totalAsset = this.getAssetBySide(marketSide, totalAssetInQuote, totalAssetInBase, tickerPrice);

      const borrowedAssetInQuote = this.getBorrowedAssetInQuote(balance, accountInfo);
      const borrowedAssetInBase = this.getBorrowedAssetInBase(balance);
      const borrowedAsset = this.getAssetBySide(marketSide, borrowedAssetInQuote, borrowedAssetInBase, tickerPrice);

      const maxLeverage = this.getMaxLeverage(marginMode, market);
      const maxBorrow = this.maxBorrowCalculator.calculate(totalAsset, borrowedAsset, marginRisk, borrowLimit, maxLeverage, marginMode, ccxt); //prettier-ignore
      result[account as Account] = maxBorrow;
    }

    return result;
  }

  private getTotalAssetInQuote(balance: ExchangeMarketBalance, accountInfo: AccountInfo) {
    let result = balance.quoteBalance?.total ?? 0;
    if (accountInfo.marginMode === "cross" && !accountInfo.crossExcludesRestBalanceInQuote) {
      result += balance.restBalanceInQuote?.total ?? 0; //TODO Exclude not marginable assets
    }
    return result;
  }

  private getTotalAssetInBase(balance: ExchangeMarketBalance) {
    return balance.baseBalance?.total ?? 0;
  }

  private getBorrowedAssetInQuote(balance: ExchangeMarketBalance, accountInfo: AccountInfo) {
    let result = balance.quoteBalance?.borrowed ?? 0;
    if (accountInfo.marginMode === "cross" && !accountInfo.crossExcludesRestBalanceInQuote) {
      result += balance.restBalanceInQuote?.borrowed ?? 0; //TODO Exclude not marginable assets
    }
    return result;
  }

  private getBorrowedAssetInBase(balance: ExchangeMarketBalance) {
    return balance.baseBalance?.borrowed ?? 0;
  }

  //prettier-ignore
  private getAssetBySide(marketSide: MarketSide, assetInQuote: number, assetInBase: number, tickerPrice: number) {
    if (marketSide === "base") {
      if (assetInQuote !== undefined) {
        return assetInBase + assetInQuote / tickerPrice;
      } else {
        return assetInBase;
      }
    }
    if (marketSide === "quote") {
      if (assetInBase !== undefined) {
        return assetInQuote + assetInBase * tickerPrice;
      } else {
        return assetInQuote;
      }
    }

    throw new Error("Wrong market side: " + marketSide);
  }

  private getMaxLeverage(marginMode: MarginMode, market: Market) {
    return marginMode === "cross"
      ? market.crossMaxLeverage ?? 1
      : marginMode === "isolated"
      ? market.isolatedMaxLeverage ?? 1
      : 1;
  }
}
