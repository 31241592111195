import { ExchangeMarketBalance } from "@/service/arbitrage-checker/ArbitrageOrder/BalanceManager";
import { MaxBorrowableBag, MaxBorrowablePostTransfer, AccountInfos, Account, MarketSide } from "ccxt";

export class BalanceTotalUpdater {
  static instance = new BalanceTotalUpdater();

  update(
    balance: ExchangeMarketBalance,
    maxBorrowableBag: MaxBorrowableBag,
    maxBorrowablePostTransfer: MaxBorrowablePostTransfer,
    accountInfos: AccountInfos,
    marketSide: MarketSide
  ) {
    for (const [account, accountInfo] of Object.entries(accountInfos)) {
      const accountBalance = balance[account as Account];
      if (accountBalance === undefined) {
        continue;
      }
      const maxBorrowable =
        accountInfo.marginMode !== null ? maxBorrowableBag[accountInfo.marginMode]?.amount : undefined;
      const maxBorrowablePostTransferValue = maxBorrowablePostTransfer[account as Account];
      if (marketSide === "base") {
        accountBalance.baseTotal = (accountBalance.baseBalance?.free ?? 0) + (maxBorrowable ?? 0);
        accountBalance.baseTotalPostTransfer = (balance.baseBalance?.free ?? 0) + (maxBorrowablePostTransferValue ?? 0);
      } else if (marketSide === "quote") {
        accountBalance.quoteTotal = (accountBalance.quoteBalance?.free ?? 0) + (maxBorrowable ?? 0);
        accountBalance.quoteTotalPostTransfer =
          (balance.quoteBalance?.free ?? 0) + (maxBorrowablePostTransferValue ?? 0);
      } else {
        throw new Error("marketSide is wrong: " + marketSide);
      }
    }
  }
}
